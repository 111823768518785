import { motion } from 'framer-motion';
import { ReactNode } from 'react';

interface GhostBtnProps {
  btnText: string;
  btnLink?: string;
  btnIcon?: ReactNode;
}

const GhostBtn = ({ btnText, btnLink, btnIcon }: GhostBtnProps) => {
  return (
    <a href={btnLink} target="_blank" rel="noreferrer">
      <motion.span
        whileHover={{ scale: 1.04, backgroundColor: '#330C45' }}
        transition={{ ease: 'easeIn', duration: 0.1 }}
        whileTap={{ scale: 1 }}
        className="flex flex-row items-center space-x-2 rounded-full border-2 border-transparent bg-transparent px-6 py-3 text-primary-flamingo"
      >
        {btnIcon}
        <motion.span
          whileHover={{ color: '#FF00E5' }}
          className="font-montserrat font-bold uppercase tracking-widest text-primary-polar"
        >
          {btnText}
        </motion.span>
      </motion.span>
    </a>
  );
};

export default GhostBtn;
