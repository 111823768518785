import { ReactComponent as EtherscanSvg } from '../assets/images/etherscan.svg';
import { ReactComponent as OpenseaSvg } from '../assets/images/opensea.svg';

import RightDrawer from '../RightDrawer/RightDrawer';

interface Item {
  type: string;
  name: string;
  floor: number;
  rarityPercentage: number;
}

export interface AssetDrawerProps {
  collectionName: string;
  itemName: string;
  etherscanUrl: string;
  openseaUrl: string;
  itemImageUrl: string;
  traits: Item[];
  onRequestClose?: () => void;
}

export default function AssetDrawer({
  collectionName,
  itemName,
  etherscanUrl,
  openseaUrl,
  itemImageUrl,
  traits,
  onRequestClose,
}: AssetDrawerProps) {
  return (
    <RightDrawer onRequestClose={onRequestClose}>
      <h2 className="text-pikachu font-semibold uppercase tracking-widest text-primary-casper">
        {collectionName}
      </h2>
      <div className="mt-3 font-montserrat text-charmeleon font-semibold text-white">
        {itemName}
      </div>
      <div className="mt-2 text-pikachu text-primary-casper">
        Owned by <span className="ml-1 text-primary-flamingo">You</span>
      </div>
      <div className="mt-3 flex items-center">
        <a target="_blank" rel="noreferrer" href={etherscanUrl} className="flex">
          <EtherscanSvg width="32" height="32" />
        </a>
        <a target="_blank" rel="noreferrer" href={openseaUrl} className="ml-3 flex">
          <OpenseaSvg width="32" height="32" />
        </a>
      </div>
      <div className="mt-6 overflow-hidden rounded-2xl">
        <img width="272" height="272" className="object-cover" src={itemImageUrl} alt={itemName} />
      </div>
      <div className="mt-6">
        {traits.map((item) => {
          return (
            <div className="border-t border-primary-woodBlue pb-6 pt-4">
              <div className="flex justify-between">
                <div>
                  <div className="text-squirtle font-semibold uppercase tracking-widest text-primary-casper">
                    {item.type}
                  </div>
                  <div className="mt-2 text-charmeleon font-semibold text-primary-polar">
                    {item.name}
                  </div>
                </div>
                <div>
                  <div className="text-squirtle font-semibold uppercase tracking-widest text-primary-casper">
                    floor
                  </div>
                  <div className="mt-2 text-charmeleon font-semibold text-primary-polar">
                    Ξ<span className="ml-1">{item.floor.toFixed(2)}</span>
                  </div>
                </div>
              </div>
              <div className="mt-2 text-squirtle font-semibold tracking-widest text-primary-casper">
                {item.rarityPercentage}%
              </div>
            </div>
          );
        })}
      </div>
    </RightDrawer>
  );
}
