import { useLazyQuery } from '@apollo/client';
import { useMoralis } from 'react-moralis';
import { motion } from 'framer-motion';

import AreaChartLava from '../../components/Charts/AreaChart/AreaChartLava';
import StatCard from '../../components/StatCard';
import './Overview.css';
import { GetOverview, GetOverviewVariables } from './__generated__/GetOverview';

import { GET_OVERVIEW_QUERY } from './OverviewQuery';
import HoldingsTable from '../../components/HoldingsTable';
import PieChartLava from '../../components/Charts/PieChart/PieChartLava';
import { useEffect } from 'react';
import PageState from '../../components/PageLoader/PageState';

export default function Overview() {
  const { isAuthenticated, user } = useMoralis();

  const userAcc = isAuthenticated ? user.get('ethAddress') : '';

  const [fetch, { data, loading }] = useLazyQuery<GetOverview, GetOverviewVariables>(
    GET_OVERVIEW_QUERY,
  );

  useEffect(() => {
    if (isAuthenticated && userAcc) {
      fetch({
        variables: {
          walletId: userAcc,
          walletCollectionsLimit: 4,
          walletCollectionsOffset: 0,
        },
      });
    }
  }, [isAuthenticated, fetch, userAcc]);

  if (!isAuthenticated) {
    return <div className="p-6">Please authenticate </div>;
  }

  if (loading) {
    return <PageState text="loading..." />;
  }

  if (!data) return <PageState text="Something is wrong bruh, refresh" />;

  const convertFiatValueToUSD = (value: number) => {
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  return (
    <div className="overview flex-grow">
      <div className="container">
        <motion.div
          initial={{ y: -40, opacity: 0 }}
          animate={{ y: 0, opacity: 1, transition: { duration: 0.5 } }}
          className="overview__container"
        >
          <div>
            <div className="overview__stats">
              <StatCard
                title="EST. Total Value"
                value={`Ξ ${data.walletSummary.totalValue.ethValue.toFixed(2).toString()}`}
                subValue={convertFiatValueToUSD(data.walletSummary.totalValue.fiatValue)}
                change={data.walletSummary.totalValueChange.percent}
              />
              <StatCard
                title="Total Assets"
                value={data.walletSummary.totalAssets.toString()}
                seeAllUrl="#"
              />
              <StatCard
                title="Total Gas Spent"
                value={`Ξ ${data.walletSummary.totalGasSpent.ethValue}`}
                subValue={convertFiatValueToUSD(data.walletSummary.totalGasSpent.fiatValue)}
              />
            </div>
            <div className="mt-6">
              <AreaChartLava title="My Wallet" data={data.walletTotalValueHistory} />
              <PieChartLava
                className="mt-6"
                data={data.walletSummary.ownedCollections.map((item) => {
                  return {
                    name: item.name,
                    value: item.count,
                  };
                })}
              />
            </div>
          </div>
          <div>
            <HoldingsTable
              items={data.walletCollections.nodes.map((col) => {
                return {
                  collectionName: col.name,
                  avatarUrl: col.imageUrl,
                  changeIn24Hour: col.stats.dailyChange.percent,
                  floorPrice: col.stats.floorPrice.ethValue.toFixed(2),
                  totalCount: col.assetCount,
                };
              })}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
}
