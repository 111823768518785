const Search = () => {
  return (
    <div className="flex h-12 max-w-xl flex-grow items-center space-x-2 border-b border-primary-woodBlue pr-8 py-4">
      <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.333 12.667A5.333 5.333 0 1 0 7.333 2a5.333 5.333 0 0 0 0 10.667ZM14 14l-2.9-2.9"
          stroke="#8BA2B2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <p className="text-inter text-charmander text-primary-casper">Search</p>
    </div>
  );
};

export default Search;
