import cx from 'classnames';
import { PropsWithChildren } from 'react';

import { ReactComponent as SettingsSvg } from '../assets/icons/settings.svg';
import CircleIconButton from '../Buttons/CircleIconButton';

import Panel from '../Panel';

interface WatchedWalletsTableProps {
  className?: string;
}

export default function WatchedWalletsTable({
  className,
  children,
}: PropsWithChildren<WatchedWalletsTableProps>) {
  return (
    <Panel className={cx('overflow-hidden', className)}>
      <div className="flex py-4 px-6 items-center justify-between border-b border-primary-woodBlue">
        <h2 className="text-primary-polar font-bold font-montserrat tracking-widest uppercase">
          Watched Wallets
        </h2>
        <div>
          <CircleIconButton>
            <SettingsSvg />
          </CircleIconButton>
        </div>
      </div>
      <table className="w-full">
        <tbody>{children}</tbody>
      </table>
    </Panel>
  );
}
