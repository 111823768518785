import { MouseEventHandler } from 'react';
import cx from 'classnames';

import CollectionTableRowLayout from './CollectionTableRowLayout';

interface HeadingProps {
  className?: string;
  name: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Heading = ({ className, name, onClick }: HeadingProps) => {
  return (
    <div className={cx(className, 'py-6')}>
      <button
        className="charmander flex items-center font-inter font-medium uppercase tracking-widest text-primary-casper"
        onClick={onClick}
      >
        <svg
          className="mr-[2]"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.27442 2.70299L5.41022 7.27509C5.0522 7.84886 5.30529 8.49512 6.05837 8.49512L11.9472 8.49512C12.6818 8.49512 12.9534 7.86094 12.5892 7.27509L9.72503 2.70299C9.34849 2.09298 8.65096 2.10506 8.27442 2.70299ZM5.41022 10.7234L8.27441 15.3015C8.65096 15.8934 9.34848 15.9055 9.72503 15.3015L12.5892 10.7234C12.9534 10.1375 12.6818 9.50337 11.9472 9.50337H6.05837C5.30529 9.50337 5.0522 10.1496 5.41022 10.7234ZM6.30528 10.5966C6.26207 10.5241 6.2559 10.3852 6.41022 10.3852H11.5892C11.7559 10.3852 11.7374 10.5362 11.6942 10.5966L9.13244 14.6492C9.03367 14.7942 8.92873 14.7459 8.87318 14.6492L6.30528 10.5966ZM8.87318 3.34925C8.93491 3.25865 9.03367 3.20429 9.13244 3.34925L11.6942 7.40192C11.7374 7.46232 11.7559 7.61331 11.5892 7.61331L6.41022 7.61331C6.2559 7.61331 6.2559 7.4744 6.30528 7.40192L8.87318 3.34925Z"
            fill="#8BA2B2"
          />
        </svg>
        {name}
      </button>
    </div>
  );
};

export default function CollectionTableHead(): JSX.Element {
  return (
    <CollectionTableRowLayout
      metaArea={<Heading name="Collection" />}
      floorArea={<Heading name="Floor" />}
      changeStatsArea={
        <>
          <Heading className="flex justify-center" name="24H %" />
          <Heading className="flex justify-center" name="1W %" />
          <Heading className="flex justify-center" name="1M %" />
        </>
      }
    />
  );
}
