import { ReactNode } from 'react';
import cx from 'classnames';

interface AssetStatCardProps {
  className?: string;
  title?: ReactNode;
  avatarUrl: string;
  name: string;
  value: string;
  valueDescription: string;
  infoFooter?: ReactNode;
  titleRightAction?: ReactNode;
  footer?: ReactNode;
}

export default function AssetStatCard({
  className,
  title,
  avatarUrl,
  name,
  value,
  valueDescription,
  infoFooter,
  footer,
  titleRightAction,
}: AssetStatCardProps) {
  return (
    <div
      className={cx(
        'rounded-2xl border border-primary-woodBlue bg-primary-stoneBlue p-6',
        className,
      )}
    >
      <div className="flex items-center justify-between">
        {title}
        {titleRightAction && <div className="flex items-center space-x-6">{titleRightAction}</div>}
      </div>
      <div className="mt-6 flex items-center space-x-6">
        <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-xl">
          <img src={avatarUrl} alt={name} className="h-full w-full object-cover" />
        </div>
        <div className="space-y-2">
          <div className="text-charmeleon font-medium text-primary-polar">{name}</div>
          <div className="flex items-baseline space-x-2">
            <span className="font-montserrat text-venusaur font-bold text-primary-polar">
              {value}
            </span>
            <span className="text-squirtle text-primary-casper">{valueDescription}</span>
          </div>
          <div className="mt-1">{infoFooter}</div>
        </div>
      </div>
      <div className="border-t border-primary-woodBlue pt-6 flex justify-between">{footer}</div>
    </div>
  );
}
