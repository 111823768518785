import { useState } from 'react';
import { Link } from 'react-router-dom';

import CollectionTableRowLayout from '../CollectionTableRowLayout';
import CollectionTableItemChanges from './CollectionTableItemChanges';
import CollectionTableItemLinks from './CollectionTableItemLinks';
import CollectionTableItemMeta from './CollectionTableItemMeta';
import CollectionTableItemToggle from './CollectionTableItemToggle';

interface CollectionItem {
  imageSrc: string;
  name: string;
}

const MAX_ASSET_COUNT = 6;

const LoadMoreButton = ({ count, href }: { count: number; href: string }) => {
  return (
    <Link
      to="/"
      className="flex h-32 w-32 flex-col items-center justify-center rounded-xl bg-primary-woodBlue"
    >
      <div className="font-montserrat text-charizard font-bold text-white">+{count}</div>
      <div className="text-pikachu font-semibold tracking-widest text-primary-casper">See All</div>
    </Link>
  );
};

export interface CollectionTableItemProps {
  logoSrc: string;
  name: string;
  floor: string;
  changes: {
    daily: number;
    weekly: number;
    monthly: number;
  };
  etherscanUrl: string;
  openseaUrl: string;
  items: CollectionItem[];
  onPartDetailClick?: (index: number) => void;
}

export default function CollectionTableItem({
  logoSrc,
  name,
  items,
  floor,
  changes,
  openseaUrl,
  etherscanUrl,
  onPartDetailClick,
}: CollectionTableItemProps): JSX.Element {
  const [open, setOpen] = useState(false);

  return (
    <div className="border-t border-primary-woodBlue bg-primary-stoneBlue">
      <div onClick={() => setOpen(!open)}>
        <CollectionTableRowLayout
          className="pb-4 pt-6"
          toggleArea={<CollectionTableItemToggle open={open} />}
          metaArea={<CollectionTableItemMeta logoSrc={logoSrc} name={name} items={items} />}
          floorArea={
            <div className="text-charmeleon font-semibold text-white">
              Ξ <span className="ml-2">{floor}</span>
            </div>
          }
          changeStatsArea={<CollectionTableItemChanges changes={changes} />}
          linksArea={
            <CollectionTableItemLinks openseaUrl={openseaUrl} etherscanUrl={etherscanUrl} />
          }
        />
      </div>
      {open && (
        <div className="ml-10 flex pb-6 pr-6 pt-4">
          {items.slice(0, MAX_ASSET_COUNT).map((item, index) => {
            return (
              <button type="button" className="mr-6" onClick={() => onPartDetailClick(index)}>
                <div className="w-32 overflow-hidden rounded-xl">
                  <img className="w-full object-cover" src={item.imageSrc} alt={item.name} />
                </div>
                <div className="mt-3 text-pikachu font-semibold text-white">{item.name}</div>
              </button>
            );
          })}
          {items.length > MAX_ASSET_COUNT && (
            <LoadMoreButton count={items.length - MAX_ASSET_COUNT} href="#" />
          )}
        </div>
      )}
    </div>
  );
}
