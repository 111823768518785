import { useState } from 'react';
import cx from 'classnames';

import CollectionTableItem from './CollectionTableItem/CollectionTableItem';
import CollectionTableHead from './CollectionTableHead';
import { CollectionTableFields } from './__generated__/CollectionTableFields';
import AssetDrawer from '../AssetDrawer';

interface CollectionTableProps {
  className?: string;
  collections: CollectionTableFields[];
}

export default function CollectionTable({
  className,
  collections,
}: CollectionTableProps): JSX.Element {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [clickedAssetIndex, setClickedAssetIndex] = useState(null);

  return (
    <>
      <div className={cx(className, 'overflow-x-auto rounded-t-3xl bg-primary-stoneBlue pl-6')}>
        <CollectionTableHead />
        {collections.map((collection) => {
          const { id, name, imageUrl, openseaLink, etherscanLink, assets, stats } = collection;

          return (
            <div key={id}>
              <CollectionTableItem
                logoSrc={imageUrl}
                name={name}
                floor={stats.floorPrice.ethValue.toFixed(2)}
                etherscanUrl={etherscanLink}
                openseaUrl={openseaLink}
                changes={{
                  daily: stats.dailyChange.percent,
                  weekly: stats.weeklyChange.percent,
                  monthly: stats.monthlyChange.percent,
                }}
                items={assets.map((asset) => {
                  return {
                    imageSrc: asset.imageUrl,
                    name: asset.name,
                  };
                })}
                onPartDetailClick={(index) => {
                  setDrawerOpen(!drawerOpen);
                  setClickedAssetIndex(index);
                }}
              />
              {drawerOpen && (
                <AssetDrawer
                  onRequestClose={() => setDrawerOpen(false)}
                  collectionName={name}
                  itemName={`${assets[clickedAssetIndex].name} ${assets[clickedAssetIndex].tokenId}`}
                  etherscanUrl={assets[clickedAssetIndex].etherscanLink}
                  openseaUrl={assets[clickedAssetIndex].openseaLink}
                  itemImageUrl={assets[clickedAssetIndex].imageUrl}
                  traits={assets[clickedAssetIndex].traits.map((trait) => ({
                    floor: trait.floorPrice,
                    name: trait.type,
                    rarityPercentage: trait.rarity,
                    type: trait.type,
                  }))}
                />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}
