import cx from 'classnames';
import { ReactNode } from 'react';

interface ChangeIndicatorProps {
  className?: string;
  change: number;
}

const getColorOfChange = (change: number): string => {
  switch (true) {
    case change > 0:
      return 'text-custom-spring';
    case change < 0:
      return 'text-custom-crimson';
    default:
      return 'text-primary-casper';
  }
};

const getIconOfChange = (change: number): ReactNode => {
  switch (true) {
    case change > 0:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 21 20"
          width="20"
          height="20"
        >
          <g
            stroke="#41FFA4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            clipPath="url(#a)"
          >
            <path d="m19.667 5-7.917 7.917L7.583 8.75 1.333 15" />
            <path d="M14.667 5h5v5" />
          </g>
          <defs>
            <clipPath id="a">
              <rect width="20" height="20" fill="#fff" transform="translate(.5)" />
            </clipPath>
          </defs>
        </svg>
      );
    case change < 0:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
          width="20"
          height="20"
        >
          <g
            stroke="#FF4163"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            clipPath="url(#a)"
          >
            <path d="M19.167 15 11.25 7.083 7.083 11.25.833 5" />
            <path d="M14.167 15h5v-5" />
          </g>
          <defs>
            <clipPath id="a">
              <rect width="20" height="20" fill="#fff" />
            </clipPath>
          </defs>
        </svg>
      );
  }
};

export default function ChangeIndicator({ className, change }: ChangeIndicatorProps) {
  return (
    <div className={cx('flex items-center', getColorOfChange(change), className)}>
      <div className="flex items-center text-squirtle font-bold">{change}%</div>
      <div className="ml-1">{getIconOfChange(change)}</div>
    </div>
  );
}
