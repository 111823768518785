import cx from 'classnames';
import { ReactNode } from 'react';

interface CollectionDetailStatCardProps {
  className?: string;
  title: string;
  value: string;
  valueRightText?: string;
  footer?: ReactNode;
}

const TxAddress = ({ address }: { address: string }) => {
  return (
    <div className="text-squirtle font-medium text-custom-aqua">
      <a href={`https://etherscan.io/address/${address}`} target="blank">
        {`${address.substring(0, 4)}...${address.slice(-4)}`}
      </a>
    </div>
  );
};

const CollectionDetailStatCard = function ({
  className,
  title,
  value,
  valueRightText,
  footer,
}: CollectionDetailStatCardProps) {
  return (
    <div
      className={cx(
        'space-y-3 rounded-2xl border border-primary-woodBlue bg-primary-stoneBlue px-6 py-4',
        className,
      )}
    >
      <h2 className="text-pikachu font-medium uppercase tracking-widest text-primary-casper">
        {title}
      </h2>
      <div className="flex items-baseline">
        <div className="font-montserrat text-venusaur font-bold text-primary-polar">{value}</div>
        {valueRightText && (
          <span className="ml-3 text-pikachu font-medium uppercase tracking-widest text-primary-casper">
            {valueRightText}
          </span>
        )}
      </div>
      {footer && <div className="mt-1">{footer}</div>}
    </div>
  );
};

CollectionDetailStatCard.Address = TxAddress;

export default CollectionDetailStatCard;
