import { useLazyQuery } from '@apollo/client';
import cx from 'classnames';
import { useEffect } from 'react';
import { useMoralis } from 'react-moralis';

import CollectionTable from '../../components/CollectionTable/CollectionTable';
import { GET_COLLECTIONS_QUERY } from './CollectionsQuery';
import { GetCollections, GetCollectionsVariables } from './__generated__/GetCollections';

interface StatCardProps {
  className?: string;
  title: string;
  value: number;
}

function StatCard({ className, title, value }: StatCardProps) {
  return (
    <div className={cx('border-l-2 border-primary-woodBlue px-7 py-2', className)}>
      <h3 className="text-pikachu font-bold uppercase tracking-widest text-primary-casper">
        {title}
      </h3>
      <div className="text-venusaur font-bold text-primary-polar">{value}</div>
    </div>
  );
}

export default function Collections() {
  const { isAuthenticated, user } = useMoralis();

  const userAcc = isAuthenticated ? user.get('ethAddress') : '';

  const [fetch, { data, loading }] = useLazyQuery<GetCollections, GetCollectionsVariables>(
    GET_COLLECTIONS_QUERY,
  );

  useEffect(() => {
    if (isAuthenticated && userAcc) {
      fetch({
        variables: {
          walletId: userAcc,
          walletCollectionsLimit: 2,
          walletCollectionsOffset: 0,
        },
      });
    }
  }, [isAuthenticated, fetch, userAcc]);

  if (!isAuthenticated) {
    return <div className="p-6">Please authenticate </div>;
  }

  if (loading) {
    return <div className="p-6">loading...</div>;
  }

  if (!data) return <div className="p-6">No data</div>;

  return (
    <div className="flex-grow bg-primary-carbonBlue pt-10 px-12">
      <div className="container">
        <div className="flex items-center justify-between">
          <StatCard title="Total Assets" value={476} />
          <StatCard title="Total Sold" value={85} />
          <StatCard title="Total Bought" value={0} />
          <StatCard title="Total Minted" value={0} />
        </div>
        <CollectionTable className="mt-8" collections={data.walletCollections.nodes} />
      </div>
    </div>
  );
}
