// import './PageLoader.css';

const PageLoader = () => {
  return (
    <svg
      width="134"
      height="165"
      viewBox="0 0 134 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* big */}
      <path
        className="flame-main big"
        d="M0.226074 125.146C13.7145 163.381 49.4371 163.883 49.4371 163.883C52.8693 164.194 62.2372 164.463 72.2517 163.043C72.2517 163.043 107.222 161.368 125.207 139.736C143.193 118.102 127.208 94.4584 121.379 87.2465C115.549 80.0346 118.728 73.1609 118.728 73.1609C114.415 79.0274 116.549 82.719 118.381 90.4329C120.212 98.1489 114.218 102.338 114.218 102.338C116.217 73.3278 93.5685 56.561 93.5685 56.561C104.06 73.498 87.4057 86.0713 78.4092 80.5387C69.4148 75.0061 73.0839 65.2789 77.9138 39.4538C82.7427 13.6277 50.7669 0.549255 50.7669 0.549255C56.2654 11.9516 56.9287 20.5046 42.609 30.0638C28.2861 39.6219 20.7925 54.5466 24.83 67.6272C28.8686 80.7067 24.957 103.01 16.1305 96.8046C7.30406 90.5988 15.2983 66.619 15.2983 66.619C-10.6816 94.1202 10.801 131.687 10.801 131.687C2.97265 128.165 0.226074 125.146 0.226074 125.146Z"
        fill="url(#paint0_linear_1432_12281)"
      />
      {/* mid */}
      <path
        className="flame-main mid"
        d="M25.0542 139.53C33.5176 163.657 55.9317 163.974 55.9317 163.974C58.0852 164.171 63.9631 164.34 70.2467 163.445C70.2467 163.445 92.1887 162.388 103.474 148.737C114.759 135.085 104.729 120.165 101.072 115.614C97.4135 111.063 99.4081 106.725 99.4081 106.725C96.7022 110.427 98.041 112.757 99.1908 117.624C100.339 122.494 96.5785 125.137 96.5785 125.137C97.833 106.83 83.622 96.2499 83.622 96.2499C90.2046 106.938 79.7552 114.872 74.1103 111.381C68.4667 107.889 70.7689 101.751 73.7994 85.4546C76.8293 69.1572 56.7661 60.9042 56.7661 60.9042C60.2161 68.0995 60.6323 73.4968 51.6474 79.5291C42.6605 85.5606 37.9586 94.9787 40.492 103.233C43.026 111.487 40.5717 125.561 35.0335 121.645C29.4953 117.729 34.5113 102.597 34.5113 102.597C18.2102 119.951 31.6895 143.657 31.6895 143.657C26.7775 141.435 25.0542 139.53 25.0542 139.53Z"
        fill="#FFA551"
      />
      {/* mini */}
      <path
        className="flame-main mini"
        d="M44.6528 151.363C49.149 163.885 61.0565 164.05 61.0565 164.05C62.2006 164.152 65.3232 164.24 68.6614 163.775C68.6614 163.775 80.3181 163.226 86.3133 156.141C92.3085 149.056 86.9801 141.313 85.0371 138.951C83.0937 136.589 84.1534 134.338 84.1534 134.338C82.7159 136.259 83.4271 137.468 84.0379 139.995C84.648 142.522 82.6501 143.894 82.6501 143.894C83.3165 134.393 75.767 128.901 75.767 128.901C79.264 134.448 73.7127 138.566 70.7139 136.754C67.7157 134.942 68.9388 131.757 70.5487 123.299C72.1584 114.841 61.4998 110.558 61.4998 110.558C63.3326 114.292 63.5537 117.093 58.7805 120.224C54.0062 123.354 51.5083 128.242 52.8541 132.526C54.2003 136.809 52.8965 144.114 49.9543 142.081C47.0122 140.049 49.6769 132.196 49.6769 132.196C41.0169 141.202 48.1778 153.505 48.1778 153.505C45.5684 152.352 44.6528 151.363 44.6528 151.363Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1432_12281"
          x1="0.226074"
          y1="82.3401"
          x2="133.506"
          y2="82.3401"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FE0F3A" />
          <stop offset="0.354167" stop-color="#EC3434" />
          <stop offset="0.729167" stop-color="#FF7C03" />
          <stop offset="1" stop-color="#FFAF66" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PageLoader;
