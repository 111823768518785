import cx from 'classnames';
import { PropsWithChildren, useState } from 'react';
import AssetDrawer from '../AssetDrawer';

import { ReactComponent as EtherscanSvg } from '../assets/images/etherscan.svg';
import { ReactComponent as OpenseaSvg } from '../assets/images/opensea.svg';

import CollectionTradeHistoryTableHeader from './CollectionTradeHistoryTableHeader';

interface CollectionTradeHistoryTableProps {
  className?: string;
}

export default function CollectionTradeHistoryTable({
  className,
  children,
}: PropsWithChildren<CollectionTradeHistoryTableProps>) {
  return (
    <table
      className={cx(
        'w-full overflow-hidden rounded-2xl border border-primary-woodBlue bg-primary-stoneBlue',
        className,
      )}
    >
      <CollectionTradeHistoryTableHeader />
      <tbody>{children}</tbody>
    </table>
  );
}

interface CollectionTradeHistoryTableItemProps {
  className?: string;
  avatarUrl: string;
  name: string;
  token: string;
  boughtDate: string;
  boughtEthValue: string;
  boughtFiatValue: string;
  boughtTx: string;
  soldDate: string;
  soldEthValue: string;
  soldFiatValue: string;
  soldTx: string;
  pnlType: 'loss' | 'profit' | 'breakeven';
  pnlEthValue: string;
  pnlFiatValue: string;
  openseaUrl;
  etherscanUrl;
}

export const CollectionTradeHistoryTableItem = ({
  avatarUrl,
  name,
  token,
  boughtDate,
  boughtEthValue,
  boughtFiatValue,
  boughtTx,
  soldDate,
  soldEthValue,
  soldFiatValue,
  soldTx,
  pnlType,
  pnlEthValue,
  pnlFiatValue,
  openseaUrl,
  etherscanUrl,
}: CollectionTradeHistoryTableItemProps) => {
  const [assetDrawerOpen, setAssetDrawerOpen] = useState(false);

  return (
    <>
      <tr>
        <td className="px-8 py-6">
          <button
            type="button"
            onClick={() => setAssetDrawerOpen(true)}
            className="flex items-center text-left"
          >
            <div className="w-[72px] h-[72px] overflow-hidden rounded-xl">
              <img src={avatarUrl} alt={name} className="h-full w-full object-cover" />
            </div>
            <div className="ml-6">
              <div className="font-medium text-primary-polar">{name}</div>
              <div className="mt-1 text-pikachu font-medium text-primary-casper">{token}</div>
            </div>
          </button>
        </td>
        <td className="px-8 py-6">
          <SoldBought
            date={boughtDate}
            value={boughtEthValue}
            fiatValue={boughtFiatValue}
            tx={boughtTx}
            bought={false}
          />
        </td>
        <td className="px-8 py-6">
          <SoldBought
            date={soldDate}
            value={soldEthValue}
            fiatValue={soldFiatValue}
            tx={soldTx}
            bought={false}
          />
        </td>
        <td className="px-8 py-6 align-baseline">
          <PNL ethValue={pnlEthValue} fiatValue={pnlFiatValue} type={pnlType} />
        </td>
        <td className="px-8 py-6">
          <div className="flex items-center justify-end">
            <div>
              <a href={openseaUrl} target="_blank" rel="noopener noreferrer">
                <OpenseaSvg width="32" height="32" />
              </a>
            </div>
            <div className="ml-6">
              <a href={etherscanUrl} target="_blank" rel="noreferrer">
                <EtherscanSvg width="32" height="32" />
              </a>
            </div>
          </div>
        </td>
      </tr>
      {assetDrawerOpen && (
        <AssetDrawer
          onRequestClose={() => setAssetDrawerOpen(false)}
          collectionName="Galactic Apes"
          itemName="GalacticApe #107"
          etherscanUrl="https://google.com.tr"
          openseaUrl="https://google.com.tr"
          itemImageUrl="https://www.figma.com/file/zrA0ZYsGueO8v567Ip56ZA/image/0cb746ac44a2fb83e155febe5298ce1d1784979d?fuid=771083755784061873"
          traits={[
            {
              type: 'MOUTH',
              name: 'Terminator',
              floor: 6.03,
              rarityPercentage: 2,
            },
            {
              type: 'MOUTH',
              name: 'Terminator',
              floor: 6.03,
              rarityPercentage: 2,
            },
            {
              type: 'MOUTH',
              name: 'Terminator',
              floor: 6.03,
              rarityPercentage: 2,
            },
            {
              type: 'MOUTH',
              name: 'Terminator',
              floor: 6.03,
              rarityPercentage: 2,
            },
          ]}
        />
      )}
    </>
  );
};

interface SoldBoughtProps {
  date: string;
  value: string;
  fiatValue: string;
  tx: string;
  bought: boolean;
}

const SoldBought = ({ date, value, fiatValue, tx, bought }: SoldBoughtProps) => {
  return (
    <div className="space-y-2">
      <div className="text-diglett uppercase tracking-wider text-primary-casper">{date}</div>
      <div className="flex items-baseline">
        <span className="font-montserrat text-bulbasaur font-semibold text-primary-polar">
          {value}
        </span>
        <span className="ml-1 text-squirtle font-normal text-primary-casper">{fiatValue}</span>
      </div>
      <div
        className={cx(
          'text-diglett uppercase text-custom-lemon',
          bought ? 'text-custom-lemon' : 'text-custom-aqua',
        )}
      >
        <a
          href={`https://etherscan.com/tx/${tx}`}
          target="_blank"
          rel="noreferrer"
        >{`${tx.substring(0, 4)}...${tx.slice(-4)}`}</a>
      </div>
    </div>
  );
};

interface PNLProps {
  type: 'loss' | 'profit' | 'breakeven';
  ethValue: string;
  fiatValue: string;
}

const PNL = ({ type, ethValue, fiatValue }: PNLProps) => {
  const colorByType = {
    loss: 'text-custom-crimson',
    profit: 'text-custom-spring',
    breakeven: 'text-primary-polar',
  };

  return (
    <div className="space-y-2">
      <div className="text-diglett uppercase text-primary-casper">{type}</div>
      <div className={cx('flex items-center', colorByType[type])}>
        <div className="text-custom-crimso font-montserrat text-charmeleon font-bold">
          {ethValue}
        </div>
        <div className="ml-2 text-squirtle font-medium">{fiatValue}</div>
      </div>
    </div>
  );
};
