import { MoralisProvider } from 'react-moralis';
import { ApolloProvider } from '@apollo/client';
import { Routes, Route } from 'react-router-dom';

import { client } from './apollo';

import './styles/main.css';

import { Toaster } from './components/Toast';
import Layout from './components/Layout';
import Overview from './routes/Overview';
import Collections from './routes/Collections';
import Collection from './routes/Collection';
import Watchlist from './routes/Watchlist/Watchlist';

function App() {
  return (
    <MoralisProvider
      appId={process.env.REACT_APP_MORALIS_APP_ID}
      serverUrl={process.env.REACT_APP_MORALIS_SERVER_URL}
    >
      <ApolloProvider client={client}>
        <Toaster />
        <Layout>
          <Routes>
            <Route path="/" element={<Overview />} />
            <Route path="/wallet" element={<Collections />} />
            <Route path="/collection/:id" element={<Collection />} />
            <Route path="/watchlist" element={<Watchlist />} />
          </Routes>
        </Layout>
      </ApolloProvider>
    </MoralisProvider>
  );
}

export default App;
