import { FC } from 'react';
import cx from 'classnames';

interface LabelProp {
  className?: string;
  text: string;
}

const Label: FC<LabelProp> = ({ className, text }) => {
  return (
    <div
      className={cx(
        'rounded-lg bg-custom-valentino px-2 py-1 font-montserrat text-pikachu font-semibold uppercase tracking-widest text-primary-flamingo',
        className,
      )}
    >
      {text}
    </div>
  );
};

export default Label;
