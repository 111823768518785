import cx from 'classnames';

import { ReactComponent as DiscordIcon } from '../../components/assets/icons/social/discord.svg';
import { ReactComponent as TwitterIcon } from '../../components/assets/icons/social/twitter.svg';
import { ReactComponent as WebsiteLinkIcon } from '../../components/assets/icons/social/website-link.svg';
import { ReactComponent as OpenseaIcon } from '../../components/assets/icons/social/opensea.svg';
import { ReactComponent as EtherscanIcon } from '../../components/assets/icons/social/etherscan.svg';
import SocialBtn from '../../components/Buttons/SocialBtn';

interface ColectionSocialButtonsProps {
  className?: string;
}

export default function ColectionSocialButtons({ className }: ColectionSocialButtonsProps) {
  return (
    <div className={cx('flex items-center space-x-3', className)}>
      <SocialBtn
        btnIcon={<DiscordIcon />}
        btnText="Discord"
        btnLink="/"
        className=""
        hoverBg="#142338"
        hoverTextColor="#7289DA"
      />
      <SocialBtn
        btnIcon={<TwitterIcon />}
        btnText="Twitter"
        btnLink="/"
        className=""
        hoverBg="#0E334F"
        hoverTextColor="#1DA1F2"
      />
      <SocialBtn
        btnIcon={<WebsiteLinkIcon />}
        btnText="Website"
        btnLink="/"
        hoverBg="#3B134C"
        hoverTextColor="#FF00E5"
      />
      <SocialBtn
        btnIcon={<OpenseaIcon />}
        btnText="OpenSea"
        btnLink="/"
        className=""
        hoverBg="#113941"
        hoverTextColor="#2BBBAD"
      />
      <SocialBtn
        btnIcon={<EtherscanIcon />}
        btnText="Explorer"
        btnLink="/"
        className=""
        hoverBg="#404040"
        hoverTextColor="#B8B8B8"
      />
    </div>
  );
}
