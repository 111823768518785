import cx from 'classnames';

import { ReactComponent as DiscordSVG } from '../assets/icons/social/discord.svg';

interface NoAccessProps {
  className?: string;
}

export default function NoAccess({ className }: NoAccessProps): JSX.Element {
  return (
    <div
      className={cx(
        'max-w-xl rounded-3xl border border-primary-woodBlue bg-primary-carbonBlue p-12 text-center',
        className,
      )}
    >
      <h2 className="font-montserrat font-bold uppercase tracking-widest text-primary-polar">
        You NEED TO have access to Lava Tools{' '}
        <span className="text-custom-crimson">Private Alpha</span>
      </h2>
      <hr className="my-6 border-primary-woodBlue" />
      <p className="font-montserrat font-medium text-primary-casper">
        We are on private alpha phase, where we are testing the Lava Tools and improving the
        experience. To become a test user please join our Discord community!
      </p>
      <a
        className="mt-6 inline-flex h-14 items-center rounded-full bg-primary-woodBlue px-6 py-3 font-inter font-bold uppercase tracking-widest"
        href="https://discord.gg/SmVe8BpX"
        target="_blank"
        rel="noreferrer"
      >
        <DiscordSVG className="text-white" />
        <span className="ml-2">Discord</span>
      </a>
    </div>
  );
}
