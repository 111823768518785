import PageLoader from './PageLoader';

interface PageStateProps {
  text?: string;
}

const PageState = ({ text }: PageStateProps) => {
  return (
    <div className="flex flex-grow flex-col items-center justify-center space-y-12">
      <PageLoader />
      <h2 className="text-charmeleon font-medium uppercase tracking-widest text-primary-casper">
        {text}
      </h2>
    </div>
  );
};

export default PageState;
