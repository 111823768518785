import gql from 'graphql-tag';
import { CollectionTableFields } from '../../components/CollectionTable/CollectionTableFields';

export const GET_COLLECTIONS_QUERY = gql`
  query GetCollections(
    $walletId: String!
    $walletCollectionsOffset: Int!
    $walletCollectionsLimit: Int!
  ) {
    walletCollections(
      wallet: $walletId
      offset: $walletCollectionsOffset
      limit: $walletCollectionsLimit
      orderBy: BY_DAILY_CHANGE
    ) {
      totalCount
      nodes {
        ...CollectionTableFields
      }
    }
  }
  ${CollectionTableFields}
`;
