import cx from 'classnames';
import { motion } from 'framer-motion';
import { PropsWithChildren } from 'react';

interface WalletBtnProps {
  className?: string;
  address?: string;
  onClick?: () => void;
}

const WalletBtn = ({ className, children, onClick }: PropsWithChildren<WalletBtnProps>) => {
  return (
    <motion.button
      className={cx(
        'flex rounded-full border-2 border-primary-flamingo bg-custom-valentino px-6 py-3 font-montserrat text-charmander font-bold uppercase tracking-widest text-primary-polar',
        className,
      )}
      type="button"
      whileTap={{ scale: 1 }}
      whileHover={{ scale: 1.06, backgroundColor: '#FF00E5', transition: { duration: 0.1 } }}
      onClick={onClick}
    >
      {children}
    </motion.button>
  );
};

export default WalletBtn;
