interface CollectionItem {
  imageSrc: string;
  name: string;
}

interface CollectionRowMetaProps {
  logoSrc: string;
  name: string;
  items: CollectionItem[];
}

export default function CollectionRowMeta({ logoSrc, name, items }: CollectionRowMetaProps) {
  return (
    <div className="flex items-center">
      <div className="w-14 overflow-hidden rounded-xl">
        <img className="w-full object-cover" width="56" height="56" src={logoSrc} alt={name} />
      </div>
      <div className="ml-4">
        <h2 className="text-charmander font-medium text-white">{name}</h2>
        <div className="mt-1 text-pikachu tracking-widest text-custom-lemon">
          {items.length} items
        </div>
      </div>
    </div>
  );
}
