import cx from 'classnames';
import { ReactNode } from 'react';

interface CollectionTableRowLayoutProps {
  className?: string;
  toggleArea?: ReactNode;
  metaArea?: ReactNode;
  floorArea?: ReactNode;
  changeStatsArea?: ReactNode;
  linksArea?: ReactNode;
}

export default function CollectionTableRowLayout({
  className,
  toggleArea,
  metaArea,
  floorArea,
  changeStatsArea,
  linksArea,
}: CollectionTableRowLayoutProps): JSX.Element {
  return (
    <div className={cx(className, 'grid-cols-[24px,276px,120px,auto,auto] grid items-center')}>
      {toggleArea && <div className="col-start-1 col-end-2">{toggleArea}</div>}
      {metaArea && <div className="col-start-2 col-end-3 ml-4">{metaArea}</div>}
      {floorArea && <div className="col-start-3 col-end-4 ml-6">{floorArea}</div>}
      {changeStatsArea && (
        <div className="grid-cols-[repeat(3,120px)] col-start-4 col-end-5 ml-6 grid">
          {changeStatsArea}
        </div>
      )}
      {linksArea && <div className="col-start-5 col-end-6 pr-6">{linksArea}</div>}
    </div>
  );
}
