import '../../styles/main.css';
import Switch from '../Switch';

interface ToggleProps {
  name?: string;
  isUsd: boolean;
  handleToggle: any;
}

const CurrencySwitch = ({ isUsd, handleToggle }: ToggleProps) => {
  return (
    <div className="flex items-center justify-center space-x-4">
      <span
        className={
          'font-montserrat text-charmender tracking-widest font-semibold ' +
          (!isUsd ? 'text-primary-flamingo ' : 'text-primary-casper')
        }
      >
        {' '}
        ETH{' '}
      </span>
      <Switch enabled={isUsd} onChange={handleToggle} />
      <span
        className={
          'font-montserrat text-charmender tracking-widest font-semibold ' +
          (isUsd ? 'text-custom-aqua' : 'text-primary-casper')
        }
      >
        {' '}
        USD{' '}
      </span>
    </div>
  );
};

export default CurrencySwitch;
