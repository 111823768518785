import cx from 'classnames';
import { motion } from 'framer-motion';

interface ConnectBtnProps {
  className?: string;
  onClick?: () => void;
}

const ConnectBtn = ({ className, onClick }: ConnectBtnProps) => {
  return (
    <motion.button
      type="button"
      whileTap={{ scale: 1 }}
      whileHover={{ scale: 1.06 }}
      onClick={onClick}
      className={cx(
        'flex rounded-full bg-primary-flamingo px-6 py-3 font-montserrat text-charmander font-bold uppercase tracking-widest text-primary-polar',
        className,
      )}
    >
      Connect
    </motion.button>
  );
};

export default ConnectBtn;
