import cx from 'classnames';
import { PropsWithChildren } from 'react';

import Label from '../Label';
import Panel from '../Panel';

import bodyImage from '../assets/images/new-collections.png';

interface NewCollectionsTableProps {
  className?: string;
}

export default function NewCollectionsTable({
  className,
  children,
}: PropsWithChildren<NewCollectionsTableProps>) {
  return (
    <Panel className={cx('overflow-hidden', className)}>
      <div className="flex py-4 px-6 items-center justify-between border-b border-primary-woodBlue">
        <h2 className="text-primary-polar text-pikachu font-bold font-montserrat tracking-widest uppercase">
          New Collections
        </h2>
        <Label text="soon" />
      </div>
      <table className="w-full">
        <tbody>
          <div className="relative">
            <div className="absolute inset-0 bg-primary-carbonBlue bg-opacity-50 backdrop-filter backdrop-blur-[2px]" />
            <img className="w-full" src={bodyImage} alt="soon" />
          </div>
        </tbody>
      </table>
    </Panel>
  );
}
