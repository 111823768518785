import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import { ReactComponent as LogoFull } from '../assets/logo/logo-full.svg';
import { ReactComponent as LogoMini } from '../assets/logo/logo-mini.svg';

import Navbar from './Navbar';
import SidebarShrinkButton from './SidebarShrinkButton';

const Sidebar = () => {
  const [isMinimized, setMinimize] = useState(false);

  return (
    <div className="relative flex h-screen flex-col bg-primary-stoneBlue">
      <div
        className={cx(
          isMinimized ? 'pr-[28px]' : 'pr-12',
          'pl-[28px] h-[104px] lex items-center border-b border-primary-woodBlue bg-primary-stoneBlue pb-8 pt-6',
        )}
      >
        <Link to="/">{isMinimized ? <LogoMini /> : <LogoFull />}</Link>
      </div>
      <SidebarShrinkButton
        className="w-[57px] h-[89px] absolute right-0 top-1/2 z-20 -translate-y-1/2 translate-x-1/2"
        minimized={isMinimized}
        onClick={() => setMinimize(!isMinimized)}
      />
      <Navbar className="hide-scrollbar flex-grow overflow-y-auto" isMinimized={isMinimized} />
    </div>
  );
};

export default Sidebar;
