import cx from 'classnames';

interface CollectionTradeHistoryTableHeaderProps {
  className?: string;
}

const BoughtSVG = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.157 19.832C5.166 20.842 6.59 21 8.281 21h7.467c1.662 0 3.086-.158 4.095-1.168C20.852 18.823 21 17.408 21 15.755v-7.48c0-1.693-.148-3.098-1.157-4.107C18.834 3.158 17.41 3 15.729 3H8.28c-1.69 0-3.115.158-4.124 1.168C3.148 5.177 3 6.582 3 8.274v7.452c0 1.692.148 3.107 1.157 4.106Zm4.639-10.37c.464 0 .761.316.761.801v1.752l-.148 1.593 1.473-1.573 3.729-3.74a.811.811 0 0 1 .593-.238c.445 0 .742.296.742.762a.817.817 0 0 1-.247.554l-3.739 3.74-1.592 1.485 1.513-.159h1.84c.494 0 .81.297.81.742 0 .456-.306.753-.79.753H8.904c-.524 0-.85-.238-.85-.842v-4.809c0-.475.297-.821.742-.821Z"
      fill="#8BA2B2"
    />
  </svg>
);

const SoldSVG = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.843 4.168C18.834 3.158 17.41 3 15.719 3H8.252c-1.662 0-3.086.158-4.095 1.168C3.148 5.177 3 6.592 3 8.245v7.48c0 1.693.148 3.098 1.157 4.107C5.166 20.842 6.59 21 8.271 21h7.448c1.69 0 3.115-.158 4.124-1.168C20.852 18.823 21 17.418 21 15.726V8.274c0-1.692-.148-3.107-1.157-4.106Zm-4.639 10.37c-.464 0-.761-.316-.761-.801v-1.752l.148-1.593-1.473 1.573-3.729 3.74a.812.812 0 0 1-.593.238c-.445 0-.742-.296-.742-.762 0-.197.099-.405.247-.554l3.739-3.74 1.592-1.485-1.513.159h-1.84c-.494 0-.81-.297-.81-.742 0-.456.306-.752.79-.752h4.837c.524 0 .85.237.85.84v4.81c0 .475-.296.821-.742.821Z"
      fill="#8BA2B2"
    />
  </svg>
);

const PNLSVG = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.303 4c1.503 0 2.768.14 3.665 1.037.896.888 1.028 2.145 1.028 3.648v6.618c0 1.503-.132 2.75-1.028 3.647-.897.897-2.162 1.037-3.665 1.037H8.685c-1.495 0-2.76-.14-3.657-1.037C4.132 18.054 4 16.806 4 15.303V8.658c0-1.468.132-2.724 1.028-3.62C5.925 4.14 7.19 4 8.667 4h6.636ZM8.326 7.5v1.563h7.348V7.5H8.326Zm.652 3.542v1.563h6.044v-1.563H8.978ZM8 14.924V16.5h8v-1.576H8Z"
      fill="#8BA2B2"
    />
  </svg>
);

export default function CollectionTradeHistoryTableHeader({
  className,
}: CollectionTradeHistoryTableHeaderProps) {
  return (
    <thead className={cx('border border-primary-woodBlue', className)}>
      <tr>
        <th className="pl-8 pr-6 py-4 text-left font-montserrat font-bold uppercase tracking-widest text-primary-polar">
          Collection trade history
        </th>
        <th className="px-6 py-4 text-squirtle font-medium uppercase tracking-widest text-primary-casper">
          <div className="flex items-center">
            <BoughtSVG />
            <span className="ml-2">Bought</span>
          </div>
        </th>
        <th className="px-6 py-4 text-squirtle font-medium uppercase tracking-widest text-primary-casper">
          <div className="flex items-center">
            <SoldSVG />
            <span className="ml-2">Sold</span>
          </div>
        </th>
        <th className="px-6 py-4 text-squirtle font-medium uppercase tracking-widest text-primary-casper">
          <div className="flex items-center">
            <PNLSVG />
            <span className="ml-2">Pnl</span>
          </div>
        </th>
        <th
          className="px-6 py-4 text-squirtle font-medium uppercase tracking-widest text-primary-casper"
          align="right"
        >
          <div className="inline-flex items-center">
            <button className="mr-2" type="button">
              <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="20" fill="#8BA2B2" fillOpacity=".1" />
                <path
                  d="M20.403 18.96a1.2 1.2 0 0 0 1.204-1.204c0-.343-.131-.58-.342-.791l-1.635-1.609a7.74 7.74 0 0 1 .809-.035c2.847 0 5.053 2.277 5.053 5.23a5.152 5.152 0 0 1-5.159 5.168 5.165 5.165 0 0 1-5.168-5.168c0-.756-.51-1.275-1.23-1.275-.739 0-1.266.519-1.266 1.275a7.661 7.661 0 0 0 7.664 7.673 7.663 7.663 0 0 0 7.673-7.673c0-4.245-3.349-7.611-7.567-7.611-.185 0-.378.008-.563.026l1.397-1.362c.203-.211.334-.457.334-.791 0-.686-.536-1.231-1.204-1.231a1.07 1.07 0 0 0-.826.352l-3.392 3.454a1.232 1.232 0 0 0-.387.896c0 .343.132.642.387.905l3.392 3.437c.211.22.492.334.826.334Z"
                  fill="#fff"
                />
              </svg>
            </button>
          </div>
        </th>
      </tr>
    </thead>
  );
}
