import cx from 'classnames';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { format, parseISO } from 'date-fns';
import { curveCardinal } from 'd3-shape';

import AreaChartHeader from '../ChartHeader';

const cardinal = curveCardinal.tension(0.5);

interface UniqueHoldersChartProps {
  className?: string;
  data: {
    date: string;
    value: number;
  }[];
}

const UniqueHoldersChart = ({ className, data }: UniqueHoldersChartProps) => {
  return (
    <div
      className={cx(
        'flex flex-col items-center space-y-12 rounded-2xl border border-primary-woodBlue bg-primary-stoneBlue px-4',
        className,
      )}
    >
      <AreaChartHeader title="Unique Holders" />
      <ResponsiveContainer width="99%" height={500} className={'uppercase font-montserrat'}>
        <AreaChart
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
          }}
        >
          <defs>
            <linearGradient id="bg-unique-holders" x1="0" y1="0" x2="0" y2="1">
              <stop offset="20%" stopColor="#FFFACD" stopOpacity={0.8} />
              <stop offset="60%" stopColor="#FFFACD" stopOpacity={0.3} />
              <stop offset="100%" stopColor="#FFFACD" stopOpacity={0.02} />
            </linearGradient>
          </defs>
          <Area
            type={cardinal}
            dataKey={'value'}
            stroke={'#FFFACD'}
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={'url(#bg-unique-holders)'}
          />
          <XAxis
            dataKey={'date'}
            axisLine={false}
            tick={{ stroke: '#8BA2B2', letterSpacing: '2', fontSize: '14px' }}
            tickLine={false}
            tickFormatter={(str) => {
              const date = parseISO(str);
              if (date.getDate() % 7 === 0) {
                return format(date, 'MMM, d');
              }
              return '';
            }}
          />
          <YAxis
            dataKey={'value'}
            axisLine={false}
            tick={{ stroke: '#8BA2B2', letterSpacing: '2', fontSize: '14px' }}
            tickLine={false}
            tickCount={8}
            tickFormatter={(number) => `${number}`}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: '#0A1826',
              border: 'solid 2px #FFFACD',
              borderRadius: '8px',
              color: '#fff',
              background: 'rgba(10, 24, 38, 0.8)',
              padding: '24px 32px',
            }}
            itemStyle={{
              color: '#fff',
            }}
            cursor={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default UniqueHoldersChart;
