import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import './SidebarTab.css';

interface SidebarTabProps {
  className?: string;
  title: string;
  icon: ReactNode;
  rightArea?: ReactNode;
  href: string;
}

export default function SidebarTab({ href, title, icon, rightArea, className }: SidebarTabProps) {
  return (
    <NavLink to={href} className={cx('sidebarTab group', className)}>
      <div className="sidebarTab__icon rounded-xl bg-primary-woodBlue p-2 text-primary-casper">
        {icon}
      </div>
      {!!title && (
        <div className="sidebarTab__title ml-4 font-montserrat text-squirtle font-semibold uppercase tracking-widest text-primary-casper">
          {title}
        </div>
      )}
      {!!rightArea && <div className="ml-2">{rightArea}</div>}
    </NavLink>
  );
}
