import cx from 'classnames';

import EthereumUrl from '../assets/images/ethereum.svg';
import { ReactComponent as ArrowRightSvg } from '../assets/icons/arrow-right.svg';
import ChangeIndicator from '../ChangeIndicator';

interface StatCardProps {
  className?: string;
  title?: string;
  value?: string;
  change?: number;
  subValue?: string;
  seeAllUrl?: string;
}

export default function StatCard({
  className,
  title,
  value,
  subValue,
  change,
  seeAllUrl,
}: StatCardProps) {
  return (
    <div
      className={cx(
        'flex flex-col space-y-4 rounded-3xl border border-primary-woodBlue bg-primary-stoneBlue p-6',
        className,
      )}
    >
      <img width="40" height="40" src={EthereumUrl} alt="ethereum" />
      <div className="space-y-3">
        <h3 className="mt-6 text-pikachu font-medium uppercase tracking-widest text-primary-casper">
          {title}
        </h3>
        <div className="text-venusaur font-bold text-white">{value}</div>
        {subValue && <div className="mt-2 text-squirtle text-primary-casper">{subValue}</div>}
      </div>
      <div className="mt-auto">
        {!!change && <ChangeIndicator change={change} />}
        {seeAllUrl && (
          <a
            href={seeAllUrl}
            className="mt-4 flex items-center text-pikachu font-bold uppercase tracking-widest text-white"
          >
            See All
            <ArrowRightSvg className="ml-2" width="24" height="24" />
          </a>
        )}
      </div>
    </div>
  );
}
