import { PropsWithChildren, ReactNode, useState } from 'react';
import cx from 'classnames';
import CollectionAssetToggleButton from './CollectionAssetsToggleButton';

interface CollectionAssetsProps {
  className?: string;
  assetCount?: string;
  children: (full: boolean) => ReactNode;
}

export default function CollectionAssets({
  className,
  assetCount,
  children,
}: PropsWithChildren<CollectionAssetsProps>) {
  const [full, setFull] = useState(false);

  return (
    <div className={cx('relative rounded-3xl bg-primary-stoneBlue px-6 py-8', className)}>
      <div className="font-bold uppercase tracking-widest text-primary-polar">
        Your Assets in this collection ({assetCount})
      </div>
      <div className="grid-cols-[repeat(auto-fill,minmax(128px,1fr))] mt-6 grid gap-6">
        {children(full)}
      </div>
      <CollectionAssetToggleButton
        className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2"
        full={full}
        onClick={() => setFull(!full)}
      />
    </div>
  );
}
