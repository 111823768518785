import cx from 'classnames';
import { useState } from 'react';
import AssetDrawer from '../../components/AssetDrawer';

interface CollectionAssetProps {
  className?: string;
  avatarUrl: string;
  name: string;
  token: string;
}

export default function CollectionAsset({
  className,
  avatarUrl,
  name,
  token,
}: CollectionAssetProps) {
  const [assetDrawerOpen, setAssetDrawerOpen] = useState(false);

  return (
    <>
      <button
        className={cx('w-32 text-left', className)}
        type="button"
        onClick={() => setAssetDrawerOpen(true)}
      >
        <div className="h-32 w-32 overflow-hidden rounded-xl">
          <img className="h-full w-full object-cover" src={avatarUrl} alt={name} />
        </div>
        <div className="mt-3 text-squirtle font-semibold text-primary-casper">{name}</div>
        <div className="mt-1 text-squirtle font-semibold text-primary-polar">{token}</div>
      </button>
      {assetDrawerOpen && (
        <AssetDrawer
          onRequestClose={() => setAssetDrawerOpen(false)}
          collectionName="Galactic Apes"
          itemName="GalacticApe #107"
          etherscanUrl="https://google.com.tr"
          openseaUrl="https://google.com.tr"
          itemImageUrl="https://www.figma.com/file/zrA0ZYsGueO8v567Ip56ZA/image/0cb746ac44a2fb83e155febe5298ce1d1784979d?fuid=771083755784061873"
          traits={[
            {
              type: 'MOUTH',
              name: 'Terminator',
              floor: 6.03,
              rarityPercentage: 2,
            },
            {
              type: 'MOUTH',
              name: 'Terminator',
              floor: 6.03,
              rarityPercentage: 2,
            },
            {
              type: 'MOUTH',
              name: 'Terminator',
              floor: 6.03,
              rarityPercentage: 2,
            },
            {
              type: 'MOUTH',
              name: 'Terminator',
              floor: 6.03,
              rarityPercentage: 2,
            },
          ]}
        />
      )}
    </>
  );
}
