import cx from 'classnames';
import { PropsWithChildren } from 'react';

import { toast as baseToast, Toaster as BaseToaster } from 'react-hot-toast';

interface ToastWrapperProps {
  className?: string;
}

export function ToastWrapper({
  className,
  children,
}: PropsWithChildren<ToastWrapperProps>): JSX.Element {
  return (
    <div
      className={cx(
        'rounded-2xl border border-primary-flamingo bg-primary-stoneBlue p-6 font-montserrat text-squirtle text-primary-polar',
        className,
      )}
    >
      {children}
    </div>
  );
}

export const Toaster = () => {
  return (
    <BaseToaster
      position="top-center"
      reverseOrder={false}
      gutter={4}
      containerStyle={{
        top: 16,
        left: 16,
        bottom: 16,
        right: 16,
      }}
      toastOptions={{
        style: {
          background: 'transparent',
          maxWidth: 'max-content',
          padding: 0,
          borderRadius: 0,
          boxShadow: 'none',
          inset: 100,
        },
        duration: 500000,
      }}
    />
  );
};

export default function toast(children, options) {
  baseToast.custom(() => <ToastWrapper>{children}</ToastWrapper>, options);
}
