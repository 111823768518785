import { Menu } from '@headlessui/react';
import cx from 'classnames';

import { ReactComponent as ChevronLeftSvg } from '../../components/assets/icons/chevron-left.svg';
import Label from '../../components/Label';

interface CollectionCompareMenuProps {
  className?: string;
}

export default function CollectionCompareMenu({ className }: CollectionCompareMenuProps) {
  return (
    <Menu as="div" className={cx('relative inline-block text-left', className)}>
      <div>
        <Menu.Button className="inline-flex w-full items-center justify-center rounded-xl border border-custom-cyprus bg-primary-stoneBlue px-4 py-3 text-pikachu font-bold uppercase tracking-widest text-primary-casper">
          Compare With
          <ChevronLeftSvg className="ml-2 w-3 -rotate-90" aria-hidden="true" />
          <Label className="ml-3" text="soon" />
        </Menu.Button>
      </div>
    </Menu>
  );
}
