import { ReactComponent as EtherscanSvg } from '../../assets/images/etherscan.svg';
import { ReactComponent as OpenseaSvg } from '../../assets/images/opensea.svg';

interface CollectionTableItemLinksProps {
  etherscanUrl: string;
  openseaUrl: string;
}

export default function CollectionTableItemLinks({
  etherscanUrl,
  openseaUrl,
}: CollectionTableItemLinksProps) {
  return (
    <div className="ml-6 flex items-center">
      <div>
        <a href={openseaUrl} target="_blank" rel="noopener noreferrer">
          <OpenseaSvg width="32" height="32" />
        </a>
      </div>
      <div className="ml-6">
        <a href={etherscanUrl} target="_blank" rel="noreferrer">
          <EtherscanSvg width="32" height="32" />
        </a>
      </div>
    </div>
  );
}
