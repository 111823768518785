import cx from 'classnames';

interface CollectionAssetToggleButtonProps {
  className?: string;
  full: boolean;
  onClick?: () => void;
}

export default function CollectionAssetToggleButton({
  className,
  full,
  onClick,
}: CollectionAssetToggleButtonProps) {
  return (
    <button className={cx('', className)} type="button" onClick={onClick}>
      {full ? (
        <svg width="89" height="57" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M44.5 57C16 57 20.5 28.5 0 28.5 0 12.76 28.76 0 44.5 0 60.24 0 89 12.76 89 28.5 68.5 28.5 73 57 44.5 57Z"
            fill="#0A1826"
          />
          <path
            d="M43.46 28.348c-.608 0-1.112.21-1.605.726l-8.624 8.813c-.376.375-.551.82-.551 1.347 0 1.09.879 1.97 1.945 1.97.55 0 1.043-.224 1.441-.634l7.407-7.64 7.383 7.64c.41.41.902.633 1.44.633 1.067 0 1.946-.879 1.946-1.969 0-.527-.187-.972-.55-1.347l-8.626-8.813c-.492-.504-.984-.726-1.605-.726Z"
            fill="#8BA2B2"
          />
        </svg>
      ) : (
        <svg width="89" height="57" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M44.5 57C16 57 20.5 28.5 0 28.5 0 12.76 28.76 0 44.5 0 60.24 0 89 12.76 89 28.5 68.5 28.5 73 57 44.5 57Z"
            fill="#0A1826"
          />
          <path
            d="M43.46 45.652c-.608 0-1.112-.21-1.605-.726l-8.624-8.813a1.821 1.821 0 0 1-.551-1.347c0-1.09.879-1.97 1.945-1.97.55 0 1.043.224 1.441.634l7.407 7.64 7.383-7.64c.41-.41.902-.633 1.44-.633 1.067 0 1.946.879 1.946 1.969 0 .527-.187.972-.55 1.347l-8.626 8.813c-.492.504-.984.726-1.605.726Z"
            fill="#8BA2B2"
          />
        </svg>
      )}
    </button>
  );
}
