import { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { format, parseISO, subDays } from 'date-fns';

import ChartHeader from '../ChartHeader';

const data: any[] | undefined = [];
for (let num = 30; num >= 0; num--) {
  data.push({
    date: subDays(new Date(), num).toISOString().substring(0, 10),
    value: 0 + Math.random(),
  });
}

interface BarChartLavaProps {
  title?: string;
}

const BarChartLava = ({ title }: BarChartLavaProps) => {
  const [isUsd, setIsUsd] = useState(false);

  return (
    <div className="flex flex-col items-center space-y-12 rounded-2xl border border-primary-woodBlue bg-primary-stoneBlue px-4">
      <ChartHeader title={title} isUsd={isUsd} handleToggle={() => setIsUsd(!isUsd)} />
      <ResponsiveContainer width="99%" height={500} className={'uppercase font-montserrat'}>
        <BarChart width={500} height={380} data={data}>
          <XAxis
            dataKey={'date'}
            axisLine={false}
            tick={{ stroke: '#8BA2B2', letterSpacing: '2', fontSize: '14px' }}
            tickLine={false}
            tickFormatter={(str) => {
              const date = parseISO(str);
              if (date.getDate() % 7 === 0) {
                return format(date, 'MMM, d');
              }
              return '';
            }}
          />

          <YAxis
            dataKey={'value'}
            axisLine={false}
            tick={{ stroke: '#8BA2B2', letterSpacing: '2', fontSize: '14px' }}
            tickLine={false}
            tickCount={8}
            tickFormatter={(number) =>
              isUsd ? `$ ${number.toFixed(2)}` : `Ξ ${number.toFixed(2)}`
            }
          />
          <Tooltip
            contentStyle={{
              backgroundColor: '#0A1826',
              border: 'transparent',
              borderRadius: '8px',
              color: '#fff',
              background: '0A1826',
              padding: '24px 32px',
            }}
            itemStyle={{
              color: '#fff',
            }}
            cursor={false}
          />
          <Bar dataKey="value" fill={isUsd ? '#00FFF0' : '#FF00E5'} radius={8} maxBarSize={24} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartLava;
