import { Menu } from '@headlessui/react';

import { ReactComponent as ChevronRightSvg } from '../assets/icons/chevron-left.svg';

export default function ChartHeaderDropdown() {
  return (
    <Menu>
      <Menu.Button className="bg-[rgba(139,162,178,0.1)] flex items-center rounded-xl px-4 py-3 text-squirtle font-medium text-white">
        All Time <ChevronRightSvg width="15" className="ml-1 -rotate-90" />
      </Menu.Button>
      {/* <Menu.Items>
        <Menu.Item>
          {({ active }) => (
            <a className={`${active && 'bg-blue-500'}`} href="/account-settings">
              Account settings
            </a>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <a className={`${active && 'bg-blue-500'}`} href="/account-settings">
              Documentation
            </a>
          )}
        </Menu.Item>
        <Menu.Item disabled>
          <span className="opacity-75">Invite a friend (coming soon!)</span>
        </Menu.Item>
      </Menu.Items> */}
    </Menu>
  );
}
