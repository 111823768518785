interface MostActiveWalletsTableProps {
  order?: number;
  walletImageUrl?: string;
  walletName?: string;
  address?: string;
  txCount?: number;
}

export default function MostActiveWalletsTable({
  order,
  walletImageUrl,
  walletName,
  address,
  txCount,
}: MostActiveWalletsTableProps) {
  return (
    <tr>
      <td className="py-4 pl-5 pr-3 align-middle">
        <div className="font-bold text-primary-casper">#{order}</div>
      </td>
      <td className="py-4 pr-8">
        <div className="flex items-center">
          <div className="w-10 h-10 rounded-lg overflow-hidden">
            <img
              src={walletImageUrl}
              alt={walletName}
              width={40}
              height={40}
              className="object-cover"
            />
          </div>
          <div className="ml-4">
            <div className="font-semibold text-squirtle text-primary-polar">{walletName}</div>
            <div className="mt-1 text-pikachu text-primary-casper">{address}</div>
          </div>
        </div>
      </td>
      <td className="py-4 pl-5 pr-8 align-middle">
        <div className="font-semibold text-squirtle text-primary-polar">{txCount} Tx</div>
        <div className="text-pikachu text-primary-casper mt-1">in 24h</div>
      </td>
    </tr>
  );
}
