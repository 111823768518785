import { useState } from 'react';
import cx from 'classnames';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { format, parseISO } from 'date-fns';
import { curveCardinal } from 'd3-shape';

import AreaChartHeader from '../ChartHeader';
import { GetOverview_walletTotalValueHistory } from '../../../routes/Overview/__generated__/GetOverview';

const convertFiatValueToUSD = (value: number) => {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
};

const cardinal = curveCardinal.tension(0.5);

interface AreaChartLavaProps {
  className?: string;
  data: GetOverview_walletTotalValueHistory;
  title: string;
}

const AreaChartLava = ({ className, data, title }: AreaChartLavaProps) => {
  const [isUsd, setisUsd] = useState(false);

  const dataUsd = data.values.map((item) => {
    return {
      date: item.date,
      value: item.value.fiatValue,
    };
  });

  const dataEth = data.values.map((item) => {
    return {
      date: item.date,
      value: item.value.ethValue,
    };
  });

  return (
    <div
      className={cx(
        'flex flex-col items-center space-y-12 rounded-2xl border border-primary-woodBlue bg-primary-stoneBlue px-4',
        className,
      )}
    >
      <AreaChartHeader title={title} isUsd={isUsd} handleToggle={() => setisUsd(!isUsd)} />
      <ResponsiveContainer width="99%" height={460} className={'uppercase font-montserrat'}>
        <AreaChart
          data={isUsd ? dataUsd : dataEth}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <defs>
            <linearGradient id="bg-eth" x1="0" y1="0" x2="0" y2="1">
              <stop offset="20%" stopColor="#FF00E5" stopOpacity={0.8} />
              <stop offset="60%" stopColor="#FF00E5" stopOpacity={0.3} />
              <stop offset="100%" stopColor="#FF00E5" stopOpacity={0.02} />
            </linearGradient>

            <linearGradient id="bg-usd" x1="0" y1="0" x2="0" y2="1">
              <stop offset="20%" stopColor="#00FFF0" stopOpacity={0.8} />
              <stop offset="60%" stopColor="#00FFF0" stopOpacity={0.3} />
              <stop offset="100%" stopColor="#00FFF0" stopOpacity={0.02} />
            </linearGradient>

            <filter id="shadow" height="200%" color="#fff">
              <feDropShadow dx="0" dy="4" stdDeviation="12" />
            </filter>
          </defs>

          <Area
            type={cardinal}
            dataKey={'value'}
            stroke={isUsd ? '#00FFF0' : '#FF00E5'}
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={isUsd ? 'url(#bg-usd)' : 'url(#bg-eth)'}
            filter="url(#shadow)"
          />

          <XAxis
            dataKey={'date'}
            axisLine={false}
            tick={{ stroke: '#8BA2B2', letterSpacing: '2', fontSize: '14px' }}
            tickLine={false}
            tickFormatter={(str) => {
              const date = parseISO(str);
              if (date.getDate() % 7 === 0) {
                return format(date, 'MMM, d');
              }
              return '';
            }}
          />

          <YAxis
            dataKey={'value'}
            axisLine={false}
            tick={{ stroke: '#8BA2B2', letterSpacing: '2', fontSize: '14px' }}
            tickLine={false}
            tickCount={8}
            tickFormatter={(number) =>
              isUsd ? `${convertFiatValueToUSD(number)}` : `Ξ ${number.toFixed(2)}`
            }
          />

          <Tooltip
            contentStyle={{
              backgroundColor: '#0A1826',
              border: 'solid 2px ' + (isUsd ? '#00FFF0' : '#FF01E5'),
              borderRadius: '8px',
              color: '#fff',
              background: 'rgba(10, 24, 38, 0.8)',
              padding: '24px 32px',
            }}
            itemStyle={{
              color: '#fff',
            }}
            cursor={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AreaChartLava;
