import ChangeIndicator from '../ChangeIndicator';
import TableSecondary, { Tbody, Th, Thead, Tr, Td } from '../TableSecondary';

interface Item {
  avatarUrl: string;
  collectionName: string;
  totalCount: number;
  floorPrice: string;
  changeIn24Hour: number;
}

interface HoldingsTableProps {
  className?: string;
  items: Item[];
}

const TableItem = ({ avatarUrl, collectionName, totalCount, floorPrice, changeIn24Hour }: Item) => {
  return (
    <Tr>
      <Td>
        <div className="flex items-center">
          <div className="w-[44] h-[44] overflow-hidden rounded-full">
            <img
              className="object-cover"
              width="44"
              height="44"
              src={avatarUrl}
              alt={collectionName}
            />
          </div>
          <div className="ml-4">
            <h3 className="text-squirtle font-semibold text-white">{collectionName}</h3>
            <div className="mt-1 text-pikachu font-bold uppercase tracking-widest">
              {totalCount} Item
            </div>
          </div>
        </div>
      </Td>
      <Td>
        <div>
          Ξ<span className="ml-1">{floorPrice}</span>
        </div>
      </Td>
      <Td>
        <ChangeIndicator change={changeIn24Hour} />
      </Td>
    </Tr>
  );
};

export default function HoldingsTable({ className, items }: HoldingsTableProps) {
  return (
    <TableSecondary className={className}>
      <Thead>
        <Tr>
          <Th className="text-left">Holdings</Th>
          <Th>Floor</Th>
          <Th>24H%</Th>
        </Tr>
      </Thead>
      <Tbody>
        {items.map((item) => (
          <TableItem key={item.collectionName} {...item} />
        ))}
      </Tbody>
    </TableSecondary>
  );
}
