import { ComponentProps, PropsWithChildren } from 'react';
import cx from 'classnames';

interface CircleIconButtonProps extends ComponentProps<'button'> {
  className?: string;
}

export default function CircleIconButton({
  className,
  children,
  ...otherProps
}: PropsWithChildren<CircleIconButtonProps>) {
  return (
    <button
      className={cx(
        'w-10 h-10 rounded-full bg-primary-woodBlue flex items-center justify-center',
        className,
      )}
      {...otherProps}
    >
      {children}
    </button>
  );
}
