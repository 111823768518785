import CollectionHeader from './CollectionHeader';
import ColectionSocialButtons from './ColectionSocialButtons';
import CollectionAsset from './CollectionAsset';
import CollectionAssets from './CollectionAssets';
import CollectionCompareMenu from './CollectionCompareMenu';
import CollectionDetailStatCard from './CollectionDetailStatCard';
import AreaChartLava from '../../components/Charts/AreaChart/AreaChartLava';
import { HistoryInterval } from '../../globalTypes';
import BarChartLava from '../../components/Charts/BarChart/BarChartLava';
import CollectionTradeHistoryTable, {
  CollectionTradeHistoryTableItem,
} from '../../components/CollectionTradeHistoryTable';
import AssetStatCard from '../../components/AssetStatCard';
import UniqueHoldersChart from '../../components/Charts/UniqueHoldersChart';

const address = '0x881Cd758c1d1838D4b3Db0007F6C2bab3B63E83D';

export default function Collection() {
  return (
    <div className="">
      <div className="container">
        <CollectionHeader
          coverImgUrl="https://s3-alpha-sig.figma.com/img/3013/6e7e/80701a108d395a41881137fdc0967b97?Expires=1638748800&Signature=OT3ewel3jgvK2D5-DLhhkhkJkwd5yPi7ATCgYSW-sllYTgkq2AL3r~KnDvecJwYTnWzZxpV79AhMwWbHeOqtyHF3mgRs~lWD13gMFcyASV4h8MO0Ou-SaT60CKpt70AHAvftc09mdDFAR~SWdjNeIaUm3NQBiX4oWkQRRpqXcY2a78EQgrE-~j2VT1Ip2nV4K1x~s6EO10LZSRpAdLEjTknAGixNxVriUUig5noo~0hkhBqFBUYSCYiX0vHI3d8LH8dtmzmifWHuWJVP1kcCagOQ7qI3RFTJm4FnpSe5KEVhRMyZFUk~ZhDgRJgjjbOEeFlDaR4s7Zxow7ychZ-3Kw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
          name="The Superlative Secret Society"
          avatarImgUrl="https://s3-alpha-sig.figma.com/img/ce9e/a346/7deed4c47c539b393516cd68c83c92bf?Expires=1638748800&Signature=c1bUf9CyVqta-czCQw8F6W0eWFE4YKL8KwgjDZns7oJL4zd9~T52HYcl11dQoJKv1VyKYRX9SaB4izW9Aomx1Sjn80fCH-1mwIT0ovIAlPB4NehzKoB4QrGhABcJmBysgiU5OpKPJx1utrsko8h8uk-Nz161X~QkZWB7cardAKrs3NJSpvvuN7-iv9wbTh8ODWAEfFoMo6Y18COpH9mZG9iBM8-iFBVhE6Ff-ASCpHU38R~2bptXyKxzFWLY5V41H6NWGEyDPCpih2VGdduIpqGCwj0kgiYecp7VLwiKqsl0pIdkL0e4yKdqvyDTilDpCu35-OD4G~9yTHBVNjqKyQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
          floorPrice="0.3"
          assetCount="11.1"
          ownerCount="5.7"
          volume="3.9"
        />
        <div className="px-12 py-6">
          <div className="flex items-center justify-between">
            <CollectionCompareMenu />
            <ColectionSocialButtons />
          </div>
          <div className="mt-10 flex space-x-6">
            <CollectionDetailStatCard
              className="w-full"
              title="Biggest Holder"
              value="230"
              valueRightText="items"
              footer={<CollectionDetailStatCard.Address address={address} />}
            />
            <CollectionDetailStatCard
              className="w-full"
              title="Biggest Holder"
              value="230"
              valueRightText="items"
              footer={<CollectionDetailStatCard.Address address={address} />}
            />
            <CollectionDetailStatCard
              className="w-full"
              title="Biggest Holder"
              value="230"
              valueRightText="items"
              footer={<CollectionDetailStatCard.Address address={address} />}
            />
            <CollectionDetailStatCard
              className="w-full"
              title="Biggest Holder"
              value="230"
              valueRightText="items"
              footer={<CollectionDetailStatCard.Address address={address} />}
            />
          </div>
          <div className="mt-6 flex space-x-6">
            <AssetStatCard
              className="flex-1"
              title="Biggest Sale"
              avatarUrl="https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1639353600&Signature=DVN4tRmTfXF7MzPUUkAo7f5sUqEfgg2vjBU6y4JqBIXoLYDlD6-WyfsO~31uDBHscOJAIJYdvgR9hJNkuTuewfcTsWsIp7uxNMlIFRvacd5H2OiVkqusRJiiPhDr0o-dsxks29nZ~~D1CGy4sg4MGyzoPBRsJAN6TGnFLeyRCW09jA4--CQmpc49KQbGQ86XNDpHTmCYur03w6Dvjb9W9WDkmAiQFT9GiMb1Cc38flqNviY6lgw2KQJNlK5rHBtQRxZEvqcJAEr7~tWZrMphQKLL-qQ0IvcIPYcXrXcT2wvfvafqcxZtqdlrHMnRev10mYZkfT27U~hzFuKoZi0m8w__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
              name="The Superlative Secret Society #1421"
              value="Ξ 230"
              valueDescription="($993,600)"
              infoFooter={
                <div className="flex items-center mt-3">
                  <div className="text-pikachu font-medium tracking-widest text-primary-casper">
                    <span className="uppercase">Buyer:</span>
                    <span className="ml-1 uppercase text-custom-aqua">
                      <a href={`https://etherscan.io/address/${address}`} target="blank">
                        {`${address.substring(0, 4)}...${address.slice(-4)}`}
                      </a>
                    </span>
                  </div>
                  <div className="ml-4 text-pikachu font-medium tracking-widest text-primary-casper">
                    <span className="uppercase">Seller:</span>
                    <span className="ml-1 uppercase text-custom-aqua">
                      <a href={`https://etherscan.io/address/${address}`} target="blank">
                        {`${address.substring(0, 4)}...${address.slice(-4)}`}
                      </a>
                    </span>
                  </div>
                </div>
              }
            />
            <AssetStatCard
              title="most traded asset"
              className="flex-1"
              avatarUrl="https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1639353600&Signature=DVN4tRmTfXF7MzPUUkAo7f5sUqEfgg2vjBU6y4JqBIXoLYDlD6-WyfsO~31uDBHscOJAIJYdvgR9hJNkuTuewfcTsWsIp7uxNMlIFRvacd5H2OiVkqusRJiiPhDr0o-dsxks29nZ~~D1CGy4sg4MGyzoPBRsJAN6TGnFLeyRCW09jA4--CQmpc49KQbGQ86XNDpHTmCYur03w6Dvjb9W9WDkmAiQFT9GiMb1Cc38flqNviY6lgw2KQJNlK5rHBtQRxZEvqcJAEr7~tWZrMphQKLL-qQ0IvcIPYcXrXcT2wvfvafqcxZtqdlrHMnRev10mYZkfT27U~hzFuKoZi0m8w__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
              name="The Superlative Secret Society #1421"
              value="Ξ 230"
              valueDescription="($993,600)"
              infoFooter={
                <div className="mt-3 flex items-center text-pikachu font-medium uppercase tracking-widest text-primary-casper">
                  2,402 sales
                  <svg
                    className="mx-4"
                    width="5"
                    height="6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.709 5.13a2.188 2.188 0 1 0 0-4.374 2.188 2.188 0 0 0 0 4.375Z"
                      fill="#8BA2B2"
                    />
                  </svg>
                  423 unique traders
                </div>
              }
            />
          </div>
          <CollectionAssets className="mt-6">
            {(full) => {
              return fakeAssets
                .slice(0, full ? fakeAssets.length : 7)
                .map((a) => (
                  <CollectionAsset
                    key={a.token}
                    avatarUrl={a.avatarUrl}
                    name={a.name}
                    token={a.token}
                  />
                ));
            }}
          </CollectionAssets>
          <AreaChartLava
            title="Floor Price"
            className="mt-12"
            data={{
              __typename: 'HistoricalData',
              interval: HistoryInterval.ONE_MONTH,
              values: [
                {
                  __typename: 'HistoricalDataValue',
                  date: new Date(),
                  value: {
                    ethValue: 22,
                    fiatValue: 44,
                    __typename: 'Currency',
                  },
                },
              ],
            }}
          />
          <div className="grid-cols-[1fr,1fr] mt-6 grid gap-6">
            <UniqueHoldersChart
              data={[
                {
                  date: new Date().toDateString(),
                  value: 1,
                },
                {
                  date: new Date().toDateString(),
                  value: 2,
                },
                {
                  date: new Date().toDateString(),
                  value: 3,
                },
                {
                  date: new Date().toDateString(),
                  value: 4,
                },
              ]}
            />
            <BarChartLava title="Volume" />
          </div>
          <CollectionTradeHistoryTable className="mt-6">
            <CollectionTradeHistoryTableItem
              avatarUrl="https://s3-alpha-sig.figma.com/img/a3f3/e61d/0870bc4d780a3dcd2c8fa15e70cb0b75?Expires=1639353600&Signature=PVWqLxx4lNnpRoUYoYVNBb09DvgV5gyyQI5GHR31Y2oyp5WFgrcIOFfZhOrpA-2gKMU7q5A33wD4euiCa20eZCzXuiObbBV-~--~h-7V76J3mAeE5iNtHgX4hyYxAtN4c0bvwZGwukhZDRFvu3~r~9d-DrlsGDoHaKDugU2ejktr4xDYle5DivXSIv8LWF1qGZZRAEgVoy7epZu1hb2BBC-Kpx9Pf9axkPzTNSmsmN0q1E3E~lrIf9mH9T3nfpB0B7~Ay4t9RDVGaKEMqA9hE6O9AbSEJ6jTSdObekXWnarhitTNhLAYTOa7Odo4zAxk7Rp8Ovo2c1XRBOLCdTonaA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
              name="The Superlative Secret Society"
              token="LAND (-136, 51)"
              boughtDate="27 Aug 2021"
              boughtEthValue="Ξ 6.9"
              boughtFiatValue="($278.288)"
              boughtTx="0xfe80d2f50ae795a43c1f3be47fb61548d19c9b8228ba89972b8fad1192935dda"
              soldDate="02 Nov 2021"
              soldEthValue="Ξ 2.9"
              soldFiatValue="($278.288)"
              soldTx="0xfe80d2f50ae795a43c1f3be47fb61548d19c9b8228ba89972b8fad1192935dda"
              pnlType="profit"
              pnlEthValue="Ξ 4"
              pnlFiatValue="($278.288)"
              openseaUrl="asd"
              etherscanUrl="asd"
            />
            <CollectionTradeHistoryTableItem
              avatarUrl="https://s3-alpha-sig.figma.com/img/a3f3/e61d/0870bc4d780a3dcd2c8fa15e70cb0b75?Expires=1639353600&Signature=PVWqLxx4lNnpRoUYoYVNBb09DvgV5gyyQI5GHR31Y2oyp5WFgrcIOFfZhOrpA-2gKMU7q5A33wD4euiCa20eZCzXuiObbBV-~--~h-7V76J3mAeE5iNtHgX4hyYxAtN4c0bvwZGwukhZDRFvu3~r~9d-DrlsGDoHaKDugU2ejktr4xDYle5DivXSIv8LWF1qGZZRAEgVoy7epZu1hb2BBC-Kpx9Pf9axkPzTNSmsmN0q1E3E~lrIf9mH9T3nfpB0B7~Ay4t9RDVGaKEMqA9hE6O9AbSEJ6jTSdObekXWnarhitTNhLAYTOa7Odo4zAxk7Rp8Ovo2c1XRBOLCdTonaA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
              name="The Superlative Secret Society"
              token="LAND (-136, 51)"
              boughtDate="27 Aug 2021"
              boughtEthValue="Ξ 6.9"
              boughtFiatValue="($278.288)"
              boughtTx="0xfe80d2f50ae795a43c1f3be47fb61548d19c9b8228ba89972b8fad1192935dda"
              soldDate="02 Nov 2021"
              soldEthValue="Ξ 2.9"
              soldFiatValue="($278.288)"
              soldTx="0xfe80d2f50ae795a43c1f3be47fb61548d19c9b8228ba89972b8fad1192935dda"
              pnlType="profit"
              pnlEthValue="Ξ 4"
              pnlFiatValue="($278.288)"
              openseaUrl="asd"
              etherscanUrl="asd"
            />
            <CollectionTradeHistoryTableItem
              avatarUrl="https://s3-alpha-sig.figma.com/img/a3f3/e61d/0870bc4d780a3dcd2c8fa15e70cb0b75?Expires=1639353600&Signature=PVWqLxx4lNnpRoUYoYVNBb09DvgV5gyyQI5GHR31Y2oyp5WFgrcIOFfZhOrpA-2gKMU7q5A33wD4euiCa20eZCzXuiObbBV-~--~h-7V76J3mAeE5iNtHgX4hyYxAtN4c0bvwZGwukhZDRFvu3~r~9d-DrlsGDoHaKDugU2ejktr4xDYle5DivXSIv8LWF1qGZZRAEgVoy7epZu1hb2BBC-Kpx9Pf9axkPzTNSmsmN0q1E3E~lrIf9mH9T3nfpB0B7~Ay4t9RDVGaKEMqA9hE6O9AbSEJ6jTSdObekXWnarhitTNhLAYTOa7Odo4zAxk7Rp8Ovo2c1XRBOLCdTonaA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
              name="The Superlative Secret Society"
              token="LAND (-136, 51)"
              boughtDate="27 Aug 2021"
              boughtEthValue="Ξ 6.9"
              boughtFiatValue="($278.288)"
              boughtTx="0xfe80d2f50ae795a43c1f3be47fb61548d19c9b8228ba89972b8fad1192935dda"
              soldDate="02 Nov 2021"
              soldEthValue="Ξ 2.9"
              soldFiatValue="($278.288)"
              soldTx="0xfe80d2f50ae795a43c1f3be47fb61548d19c9b8228ba89972b8fad1192935dda"
              pnlType="profit"
              pnlEthValue="Ξ 4"
              pnlFiatValue="($278.288)"
              openseaUrl="asd"
              etherscanUrl="asd"
            />
          </CollectionTradeHistoryTable>
        </div>
      </div>
    </div>
  );
}

// const fakeAssetDrawerDetail = {
//   collectionName: 'Galactic Apes',
//   itemName: 'GalacticApe #107',
//   etherscanUrl: 'https://google.com.tr',
//   openseaUrl: 'https://google.com.tr',
//   itemImageUrl:
//     'https://www.figma.com/file/zrA0ZYsGueO8v567Ip56ZA/image/0cb746ac44a2fb83e155febe5298ce1d1784979d?fuid:771083755784061873',
//   traits: [
//     {
//       type: 'MOUTH',
//       name: 'Terminator',
//       floor: 6.03,
//       rarityPercentage: 2,
//     },
//     {
//       type: 'MOUTH',
//       name: 'Terminator',
//       floor: 6.03,
//       rarityPercentage: 2,
//     },
//     {
//       type: 'MOUTH',
//       name: 'Terminator',
//       floor: 6.03,
//       rarityPercentage: 2,
//     },
//     {
//       type: 'MOUTH',
//       name: 'Terminator',
//       floor: 6.03,
//       rarityPercentage: 2,
//     },
//   ],
// };

const fakeAssets = [
  {
    avatarUrl:
      'https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1638748800&Signature=eiemzyU6PTq70uAOyC~99eztprEsXtCGxiB9I~POPGIg1FBknl2bJAdNHhpjWXXQJht17tlAXBYuOhT7haSgLmq20nmV8FDO~HKzvDJNhv2ReOBAz3fwD6SIlaHmzlOYVQKmuvZr30beOCA2TdcDDsKYa5TiM2Z4qTzFoJBSymBkONqTXUpv2GBzNicMhdB~eeqCcJQ-NgppTLwzIgvuYmv9UHb9Wj3gggdqZ6b82-oscgEz85twGq-hCGkHjWjyjfHp1FrxJdlOLo20GQ~011ro27zCjwS2KeL4-HMiSp-nVKyg~oZ28lhqd8xWgGsEFQtjw3sqH-at8PT-HOkJIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    name: 'The Superlative Secret Society',
    token: '#123',
  },
  {
    avatarUrl:
      'https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1638748800&Signature=eiemzyU6PTq70uAOyC~99eztprEsXtCGxiB9I~POPGIg1FBknl2bJAdNHhpjWXXQJht17tlAXBYuOhT7haSgLmq20nmV8FDO~HKzvDJNhv2ReOBAz3fwD6SIlaHmzlOYVQKmuvZr30beOCA2TdcDDsKYa5TiM2Z4qTzFoJBSymBkONqTXUpv2GBzNicMhdB~eeqCcJQ-NgppTLwzIgvuYmv9UHb9Wj3gggdqZ6b82-oscgEz85twGq-hCGkHjWjyjfHp1FrxJdlOLo20GQ~011ro27zCjwS2KeL4-HMiSp-nVKyg~oZ28lhqd8xWgGsEFQtjw3sqH-at8PT-HOkJIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    name: 'The Superlative Secret Society',
    token: '#123',
  },
  {
    avatarUrl:
      'https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1638748800&Signature=eiemzyU6PTq70uAOyC~99eztprEsXtCGxiB9I~POPGIg1FBknl2bJAdNHhpjWXXQJht17tlAXBYuOhT7haSgLmq20nmV8FDO~HKzvDJNhv2ReOBAz3fwD6SIlaHmzlOYVQKmuvZr30beOCA2TdcDDsKYa5TiM2Z4qTzFoJBSymBkONqTXUpv2GBzNicMhdB~eeqCcJQ-NgppTLwzIgvuYmv9UHb9Wj3gggdqZ6b82-oscgEz85twGq-hCGkHjWjyjfHp1FrxJdlOLo20GQ~011ro27zCjwS2KeL4-HMiSp-nVKyg~oZ28lhqd8xWgGsEFQtjw3sqH-at8PT-HOkJIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    name: 'The Superlative Secret Society',
    token: '#123',
  },
  {
    avatarUrl:
      'https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1638748800&Signature=eiemzyU6PTq70uAOyC~99eztprEsXtCGxiB9I~POPGIg1FBknl2bJAdNHhpjWXXQJht17tlAXBYuOhT7haSgLmq20nmV8FDO~HKzvDJNhv2ReOBAz3fwD6SIlaHmzlOYVQKmuvZr30beOCA2TdcDDsKYa5TiM2Z4qTzFoJBSymBkONqTXUpv2GBzNicMhdB~eeqCcJQ-NgppTLwzIgvuYmv9UHb9Wj3gggdqZ6b82-oscgEz85twGq-hCGkHjWjyjfHp1FrxJdlOLo20GQ~011ro27zCjwS2KeL4-HMiSp-nVKyg~oZ28lhqd8xWgGsEFQtjw3sqH-at8PT-HOkJIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    name: 'The Superlative Secret Society',
    token: '#123',
  },
  {
    avatarUrl:
      'https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1638748800&Signature=eiemzyU6PTq70uAOyC~99eztprEsXtCGxiB9I~POPGIg1FBknl2bJAdNHhpjWXXQJht17tlAXBYuOhT7haSgLmq20nmV8FDO~HKzvDJNhv2ReOBAz3fwD6SIlaHmzlOYVQKmuvZr30beOCA2TdcDDsKYa5TiM2Z4qTzFoJBSymBkONqTXUpv2GBzNicMhdB~eeqCcJQ-NgppTLwzIgvuYmv9UHb9Wj3gggdqZ6b82-oscgEz85twGq-hCGkHjWjyjfHp1FrxJdlOLo20GQ~011ro27zCjwS2KeL4-HMiSp-nVKyg~oZ28lhqd8xWgGsEFQtjw3sqH-at8PT-HOkJIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    name: 'The Superlative Secret Society',
    token: '#123',
  },
  {
    avatarUrl:
      'https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1638748800&Signature=eiemzyU6PTq70uAOyC~99eztprEsXtCGxiB9I~POPGIg1FBknl2bJAdNHhpjWXXQJht17tlAXBYuOhT7haSgLmq20nmV8FDO~HKzvDJNhv2ReOBAz3fwD6SIlaHmzlOYVQKmuvZr30beOCA2TdcDDsKYa5TiM2Z4qTzFoJBSymBkONqTXUpv2GBzNicMhdB~eeqCcJQ-NgppTLwzIgvuYmv9UHb9Wj3gggdqZ6b82-oscgEz85twGq-hCGkHjWjyjfHp1FrxJdlOLo20GQ~011ro27zCjwS2KeL4-HMiSp-nVKyg~oZ28lhqd8xWgGsEFQtjw3sqH-at8PT-HOkJIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    name: 'The Superlative Secret Society',
    token: '#123',
  },
  {
    avatarUrl:
      'https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1638748800&Signature=eiemzyU6PTq70uAOyC~99eztprEsXtCGxiB9I~POPGIg1FBknl2bJAdNHhpjWXXQJht17tlAXBYuOhT7haSgLmq20nmV8FDO~HKzvDJNhv2ReOBAz3fwD6SIlaHmzlOYVQKmuvZr30beOCA2TdcDDsKYa5TiM2Z4qTzFoJBSymBkONqTXUpv2GBzNicMhdB~eeqCcJQ-NgppTLwzIgvuYmv9UHb9Wj3gggdqZ6b82-oscgEz85twGq-hCGkHjWjyjfHp1FrxJdlOLo20GQ~011ro27zCjwS2KeL4-HMiSp-nVKyg~oZ28lhqd8xWgGsEFQtjw3sqH-at8PT-HOkJIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    name: 'The Superlative Secret Society',
    token: '#123',
  },
  {
    avatarUrl:
      'https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1638748800&Signature=eiemzyU6PTq70uAOyC~99eztprEsXtCGxiB9I~POPGIg1FBknl2bJAdNHhpjWXXQJht17tlAXBYuOhT7haSgLmq20nmV8FDO~HKzvDJNhv2ReOBAz3fwD6SIlaHmzlOYVQKmuvZr30beOCA2TdcDDsKYa5TiM2Z4qTzFoJBSymBkONqTXUpv2GBzNicMhdB~eeqCcJQ-NgppTLwzIgvuYmv9UHb9Wj3gggdqZ6b82-oscgEz85twGq-hCGkHjWjyjfHp1FrxJdlOLo20GQ~011ro27zCjwS2KeL4-HMiSp-nVKyg~oZ28lhqd8xWgGsEFQtjw3sqH-at8PT-HOkJIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    name: 'The Superlative Secret Society',
    token: '#123',
  },
  {
    avatarUrl:
      'https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1638748800&Signature=eiemzyU6PTq70uAOyC~99eztprEsXtCGxiB9I~POPGIg1FBknl2bJAdNHhpjWXXQJht17tlAXBYuOhT7haSgLmq20nmV8FDO~HKzvDJNhv2ReOBAz3fwD6SIlaHmzlOYVQKmuvZr30beOCA2TdcDDsKYa5TiM2Z4qTzFoJBSymBkONqTXUpv2GBzNicMhdB~eeqCcJQ-NgppTLwzIgvuYmv9UHb9Wj3gggdqZ6b82-oscgEz85twGq-hCGkHjWjyjfHp1FrxJdlOLo20GQ~011ro27zCjwS2KeL4-HMiSp-nVKyg~oZ28lhqd8xWgGsEFQtjw3sqH-at8PT-HOkJIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    name: 'The Superlative Secret Society',
    token: '#123',
  },
  {
    avatarUrl:
      'https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1638748800&Signature=eiemzyU6PTq70uAOyC~99eztprEsXtCGxiB9I~POPGIg1FBknl2bJAdNHhpjWXXQJht17tlAXBYuOhT7haSgLmq20nmV8FDO~HKzvDJNhv2ReOBAz3fwD6SIlaHmzlOYVQKmuvZr30beOCA2TdcDDsKYa5TiM2Z4qTzFoJBSymBkONqTXUpv2GBzNicMhdB~eeqCcJQ-NgppTLwzIgvuYmv9UHb9Wj3gggdqZ6b82-oscgEz85twGq-hCGkHjWjyjfHp1FrxJdlOLo20GQ~011ro27zCjwS2KeL4-HMiSp-nVKyg~oZ28lhqd8xWgGsEFQtjw3sqH-at8PT-HOkJIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    name: 'The Superlative Secret Society',
    token: '#123',
  },
  {
    avatarUrl:
      'https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1638748800&Signature=eiemzyU6PTq70uAOyC~99eztprEsXtCGxiB9I~POPGIg1FBknl2bJAdNHhpjWXXQJht17tlAXBYuOhT7haSgLmq20nmV8FDO~HKzvDJNhv2ReOBAz3fwD6SIlaHmzlOYVQKmuvZr30beOCA2TdcDDsKYa5TiM2Z4qTzFoJBSymBkONqTXUpv2GBzNicMhdB~eeqCcJQ-NgppTLwzIgvuYmv9UHb9Wj3gggdqZ6b82-oscgEz85twGq-hCGkHjWjyjfHp1FrxJdlOLo20GQ~011ro27zCjwS2KeL4-HMiSp-nVKyg~oZ28lhqd8xWgGsEFQtjw3sqH-at8PT-HOkJIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    name: 'The Superlative Secret Society',
    token: '#123',
  },
  {
    avatarUrl:
      'https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1638748800&Signature=eiemzyU6PTq70uAOyC~99eztprEsXtCGxiB9I~POPGIg1FBknl2bJAdNHhpjWXXQJht17tlAXBYuOhT7haSgLmq20nmV8FDO~HKzvDJNhv2ReOBAz3fwD6SIlaHmzlOYVQKmuvZr30beOCA2TdcDDsKYa5TiM2Z4qTzFoJBSymBkONqTXUpv2GBzNicMhdB~eeqCcJQ-NgppTLwzIgvuYmv9UHb9Wj3gggdqZ6b82-oscgEz85twGq-hCGkHjWjyjfHp1FrxJdlOLo20GQ~011ro27zCjwS2KeL4-HMiSp-nVKyg~oZ28lhqd8xWgGsEFQtjw3sqH-at8PT-HOkJIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    name: 'The Superlative Secret Society',
    token: '#123',
  },
  {
    avatarUrl:
      'https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1638748800&Signature=eiemzyU6PTq70uAOyC~99eztprEsXtCGxiB9I~POPGIg1FBknl2bJAdNHhpjWXXQJht17tlAXBYuOhT7haSgLmq20nmV8FDO~HKzvDJNhv2ReOBAz3fwD6SIlaHmzlOYVQKmuvZr30beOCA2TdcDDsKYa5TiM2Z4qTzFoJBSymBkONqTXUpv2GBzNicMhdB~eeqCcJQ-NgppTLwzIgvuYmv9UHb9Wj3gggdqZ6b82-oscgEz85twGq-hCGkHjWjyjfHp1FrxJdlOLo20GQ~011ro27zCjwS2KeL4-HMiSp-nVKyg~oZ28lhqd8xWgGsEFQtjw3sqH-at8PT-HOkJIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    name: 'The Superlative Secret Society',
    token: '#123',
  },
  {
    avatarUrl:
      'https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1638748800&Signature=eiemzyU6PTq70uAOyC~99eztprEsXtCGxiB9I~POPGIg1FBknl2bJAdNHhpjWXXQJht17tlAXBYuOhT7haSgLmq20nmV8FDO~HKzvDJNhv2ReOBAz3fwD6SIlaHmzlOYVQKmuvZr30beOCA2TdcDDsKYa5TiM2Z4qTzFoJBSymBkONqTXUpv2GBzNicMhdB~eeqCcJQ-NgppTLwzIgvuYmv9UHb9Wj3gggdqZ6b82-oscgEz85twGq-hCGkHjWjyjfHp1FrxJdlOLo20GQ~011ro27zCjwS2KeL4-HMiSp-nVKyg~oZ28lhqd8xWgGsEFQtjw3sqH-at8PT-HOkJIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    name: 'The Superlative Secret Society',
    token: '#123',
  },
  {
    avatarUrl:
      'https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1638748800&Signature=eiemzyU6PTq70uAOyC~99eztprEsXtCGxiB9I~POPGIg1FBknl2bJAdNHhpjWXXQJht17tlAXBYuOhT7haSgLmq20nmV8FDO~HKzvDJNhv2ReOBAz3fwD6SIlaHmzlOYVQKmuvZr30beOCA2TdcDDsKYa5TiM2Z4qTzFoJBSymBkONqTXUpv2GBzNicMhdB~eeqCcJQ-NgppTLwzIgvuYmv9UHb9Wj3gggdqZ6b82-oscgEz85twGq-hCGkHjWjyjfHp1FrxJdlOLo20GQ~011ro27zCjwS2KeL4-HMiSp-nVKyg~oZ28lhqd8xWgGsEFQtjw3sqH-at8PT-HOkJIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    name: 'The Superlative Secret Society',
    token: '#123',
  },
  {
    avatarUrl:
      'https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1638748800&Signature=eiemzyU6PTq70uAOyC~99eztprEsXtCGxiB9I~POPGIg1FBknl2bJAdNHhpjWXXQJht17tlAXBYuOhT7haSgLmq20nmV8FDO~HKzvDJNhv2ReOBAz3fwD6SIlaHmzlOYVQKmuvZr30beOCA2TdcDDsKYa5TiM2Z4qTzFoJBSymBkONqTXUpv2GBzNicMhdB~eeqCcJQ-NgppTLwzIgvuYmv9UHb9Wj3gggdqZ6b82-oscgEz85twGq-hCGkHjWjyjfHp1FrxJdlOLo20GQ~011ro27zCjwS2KeL4-HMiSp-nVKyg~oZ28lhqd8xWgGsEFQtjw3sqH-at8PT-HOkJIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    name: 'The Superlative Secret Society',
    token: '#123',
  },
  {
    avatarUrl:
      'https://s3-alpha-sig.figma.com/img/f49b/c027/0f84e7eeab69a68cb32b2cf2035c5af2?Expires=1638748800&Signature=eiemzyU6PTq70uAOyC~99eztprEsXtCGxiB9I~POPGIg1FBknl2bJAdNHhpjWXXQJht17tlAXBYuOhT7haSgLmq20nmV8FDO~HKzvDJNhv2ReOBAz3fwD6SIlaHmzlOYVQKmuvZr30beOCA2TdcDDsKYa5TiM2Z4qTzFoJBSymBkONqTXUpv2GBzNicMhdB~eeqCcJQ-NgppTLwzIgvuYmv9UHb9Wj3gggdqZ6b82-oscgEz85twGq-hCGkHjWjyjfHp1FrxJdlOLo20GQ~011ro27zCjwS2KeL4-HMiSp-nVKyg~oZ28lhqd8xWgGsEFQtjw3sqH-at8PT-HOkJIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    name: 'The Superlative Secret Society',
    token: '#123',
  },
];
