import { PropsWithChildren } from 'react';

interface RightDrawerProps {
  onRequestClose?: () => void;
}

export default function RightDrawer({
  children,
  onRequestClose,
}: PropsWithChildren<RightDrawerProps>) {
  return (
    <div className="fixed inset-0 z-20 h-full w-full">
      <div className="z-[-1] absolute inset-0 bg-black bg-opacity-80" onClick={onRequestClose} />
      <div className="rounded-bl-[40px] rounded-tl-[40px] fixed bottom-0 right-0 top-0 flex bg-primary-darkPearl">
        <div className="overflow-y-auto p-10">{children}</div>
        <button
          className="w-[57px] absolute left-0 top-1/2 -translate-x-1/2 -translate-y-1/2"
          onClick={onRequestClose}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 57 89">
            <path
              fill="#020508"
              d="M57 44.5C57 73 28.5 68.5 28.5 89 12.76 89 0 60.24 0 44.5 0 28.76 12.76 0 28.5 0 28.5 20.5 57 16 57 44.5Z"
            />
            <path
              fill="#8BA2B2"
              d="M18.492 50.672c-.656.656-.691 1.887.024 2.601.703.704 1.933.68 2.601.012l5.11-5.11 5.097 5.11c.703.68 1.899.692 2.602-.023.715-.715.715-1.91.023-2.602l-5.097-5.097 5.097-5.11c.692-.691.692-1.887-.023-2.601-.703-.715-1.899-.704-2.602-.024l-5.097 5.11-5.11-5.11c-.668-.668-1.898-.691-2.601.024-.715.703-.68 1.933-.024 2.59l5.11 5.12-5.11 5.11Z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
