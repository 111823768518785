import cx from 'classnames';

import SidebarTab from './SidebarTab/SidebarTab';
import Label from '../Label';

import { ReactComponent as HomeIcon } from '../assets/icons/home.svg';
import { ReactComponent as AlertIcon } from '../assets/icons/alert.svg';
import { ReactComponent as CalendarIcon } from '../assets/icons/calendar.svg';
import { ReactComponent as CogIcon } from '../assets/icons/cog.svg';
import { ReactComponent as FireIcon } from '../assets/icons/fire.svg';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';
import { ReactComponent as WalletIcon } from '../assets/icons/wallet.svg';
import { ReactComponent as WatchlistIcon } from '../assets/icons/watchlist.svg';

interface NavbarProps {
  className?: string;
  isMinimized?: boolean;
}

const Navbar = ({ className, isMinimized }: NavbarProps) => {
  return (
    <nav className={cx({ 'px-4': isMinimized }, 'flex flex-col px-4 py-8', className)}>
      <div>
        <SidebarTab
          className="mt-6"
          href="/"
          title={!isMinimized && 'Overview'}
          icon={<HomeIcon />}
        />
        <SidebarTab
          className="mt-6"
          href="/wallet"
          title={!isMinimized && 'MyWallet'}
          icon={<WalletIcon />}
        />
        <SidebarTab
          className="mt-6"
          href="/watchlist"
          title={!isMinimized && 'Watchlist'}
          icon={<WatchlistIcon />}
        />
        <SidebarTab
          className="mt-6"
          href="/explore"
          title={!isMinimized && 'Explore'}
          icon={<SearchIcon />}
        />
      </div>
      <div className="mt-auto">
        <SidebarTab
          className="mt-6"
          href="/hot"
          title={!isMinimized && 'Hot'}
          icon={<FireIcon />}
          rightArea={!isMinimized ? <Label text="SOON" /> : ''}
        />
        <SidebarTab
          className="mt-6"
          href="/alert"
          title={!isMinimized && 'Alert'}
          icon={<AlertIcon />}
          rightArea={!isMinimized ? <Label text="SOON" /> : ''}
        />
        <SidebarTab
          className="mt-6"
          href="/calendar"
          title={!isMinimized && 'Calendar'}
          icon={<CalendarIcon />}
          rightArea={!isMinimized ? <Label text="SOON" /> : ''}
        />
        <SidebarTab
          className="mt-16"
          href="/settings"
          title={!isMinimized && 'Settings'}
          icon={<CogIcon />}
        />
      </div>
    </nav>
  );
};

export default Navbar;
