import cx from 'classnames';
import { PropsWithChildren } from 'react';

import Panel from '../Panel';

interface MostActiveWalletsTableProps {
  className?: string;
}

export default function MostActiveWalletsTable({
  className,
  children,
}: PropsWithChildren<MostActiveWalletsTableProps>) {
  return (
    <Panel className={cx('overflow-hidden', className)}>
      <div className="flex py-4 px-6 items-center justify-between border-b border-primary-woodBlue">
        <h2 className="text-primary-polar text-pikachu font-bold font-montserrat tracking-widest uppercase">
          Most Active Wallets
        </h2>
      </div>
      <table className="w-full">
        <tbody>{children}</tbody>
      </table>
    </Panel>
  );
}
