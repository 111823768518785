export default function CollectionTableItemToggle({ open }: { open: boolean }) {
  return (
    <div className="flex">
      {open ? (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <path
            fill="#fff"
            d="M11.992 20.953c4.735 0 8.594-3.851 8.594-8.586 0-4.734-3.86-8.586-8.594-8.586s-8.586 3.852-8.586 8.586c0 4.735 3.852 8.586 8.586 8.586ZM13.18 9.828l2.867 3.086c.406.445.406 1.149.015 1.524-.46.43-1.14.43-1.554-.016l-2.516-2.703-2.508 2.703c-.414.445-1.093.445-1.562.015-.39-.374-.39-1.07.023-1.507l2.867-3.086c.618-.672 1.743-.68 2.368-.016Z"
          />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <path
            fill="#8BA2B2"
            d="M11.992 20.953c4.735 0 8.594-3.851 8.594-8.586 0-4.734-3.86-8.586-8.594-8.586s-8.586 3.852-8.586 8.586c0 4.735 3.852 8.586 8.586 8.586Zm1.188-6.055c-.625.672-1.75.665-2.367-.007l-2.868-3.086c-.414-.446-.414-1.14-.023-1.508.469-.43 1.148-.43 1.562.008l2.508 2.71 2.516-2.71c.406-.438 1.094-.438 1.555-.008.39.367.39 1.078-.016 1.515l-2.867 3.086Z"
          />
        </svg>
      )}
    </div>
  );
}
