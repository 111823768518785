import cx from 'classnames';
import { Switch as BaseSwitch } from '@headlessui/react';

interface SwitchProps {
  className?: string;
  enabled?: boolean;
  onChange: () => void;
}

export default function Switch({ className, enabled, onChange }: SwitchProps) {
  return (
    <BaseSwitch
      checked={enabled}
      onChange={onChange}
      className={cx(
        'relative inline-flex items-center flex-shrink-0 h-[24px] w-[48px] border-1 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75',
        enabled ? 'bg-custom-tiber' : ' bg-custom-valentino',
        className,
      )}
    >
      <span
        aria-hidden="true"
        className={cx(
          'pointer-events-none inline-block h-[22px] w-[22px] rounded-full transform ring-0 transition ease-in-out duration-200',
          enabled ? 'translate-x-[25px] bg-custom-aqua' : 'translate-x-[1px] bg-primary-flamingo',
        )}
      />
    </BaseSwitch>
  );
}
