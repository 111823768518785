import cx from 'classnames';
import { PropsWithChildren } from 'react';

interface SubmitButtonProps {
  className?: string;
}

export default function Button({ className, children }: PropsWithChildren<SubmitButtonProps>) {
  return (
    <button
      className={cx(
        'rounded-2xl bg-custom-cyprus text-custom-spring uppercase text-squirtle tracking-widest font-bold font-montserrat px-6 py-4 hover:bg-custom-spring hover:text-black transition-colors',
        className,
      )}
    >
      {children}
    </button>
  );
}
