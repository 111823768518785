import ChangeIndicator from '../../ChangeIndicator';

interface CollectionTableItemChangesProps {
  changes: {
    daily: number;
    weekly: number;
    monthly: number;
  };
}

export default function CollectionTableItemChanges({ changes }: CollectionTableItemChangesProps) {
  return (
    <>
      {Object.entries(changes).map(([_, change]) => {
        return <ChangeIndicator className="justify-center" change={change} />;
      })}
    </>
  );
}
