import { Menu } from '@headlessui/react';
import cx from 'classnames';
import { PropsWithChildren } from 'react';

import { ReactComponent as SettingsSvg } from '../assets/icons/settings.svg';
import CircleIconButton from '../Buttons/CircleIconButton';

import { ReactComponent as ChevronRightSvg } from '../assets/icons/chevron-left.svg';

import Panel from '../Panel';

interface WatchedCollectionsTableProps {
  className?: string;
}

export default function WatchedCollectionsTable({
  className,
  children,
}: PropsWithChildren<WatchedCollectionsTableProps>) {
  return (
    <Panel className={cx('overflow-hidden', className)}>
      <div className="flex py-4 px-6 items-center justify-between border-b border-primary-woodBlue">
        <h2 className="text-primary-polar font-bold font-montserrat tracking-widest uppercase">
          Watched Collections
        </h2>
        <div className="flex items-center">
          <Menu>
            <Menu.Button className="bg-[rgba(139,162,178,0.1)] flex items-center rounded-xl px-4 py-3 text-squirtle font-medium text-white">
              1 Hour <ChevronRightSvg width="15" className="ml-1 -rotate-90" />
            </Menu.Button>
          </Menu>
          <CircleIconButton className="ml-4">
            <SettingsSvg />
          </CircleIconButton>
        </div>
      </div>
      <table className="w-full">
        <thead>
          <tr>
            <td />
            <td className="px-6 py-4 uppercase tracking-widest font-medium text-squirtle text-primary-casper">
              Floor
            </td>
            <td className="px-6 py-4 uppercase tracking-widest font-medium text-squirtle text-primary-casper">
              Volume
            </td>
            <td className="px-6 py-4 uppercase tracking-widest font-medium text-squirtle text-primary-casper">
              Holders
            </td>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </Panel>
  );
}
