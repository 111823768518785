import ToggleSwitch from '../CurrencySwitch/CurrencySwitch';
import AreaChartDropdown from './ChartHeaderDropdown';

interface ChartHeaderProps {
  title?: string;
  isUsd?: boolean;
  handleToggle?: any;
}

const ChartHeader = ({ title, isUsd, handleToggle }: ChartHeaderProps) => {
  return (
    <div className="flex w-full items-center justify-between p-4">
      <h6 className="font-montserrat text-charmeleon font-bold uppercase tracking-widest text-primary-polar">
        {title}
      </h6>
      {handleToggle && <ToggleSwitch isUsd={isUsd} handleToggle={handleToggle} />}
      <div className="flex items-center">
        <button className="mr-2" type="button">
          <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#8BA2B2" fillOpacity=".1" />
            <path
              d="M20.403 18.96a1.2 1.2 0 0 0 1.204-1.204c0-.343-.131-.58-.342-.791l-1.635-1.609a7.74 7.74 0 0 1 .809-.035c2.847 0 5.053 2.277 5.053 5.23a5.152 5.152 0 0 1-5.159 5.168 5.165 5.165 0 0 1-5.168-5.168c0-.756-.51-1.275-1.23-1.275-.739 0-1.266.519-1.266 1.275a7.661 7.661 0 0 0 7.664 7.673 7.663 7.663 0 0 0 7.673-7.673c0-4.245-3.349-7.611-7.567-7.611-.185 0-.378.008-.563.026l1.397-1.362c.203-.211.334-.457.334-.791 0-.686-.536-1.231-1.204-1.231a1.07 1.07 0 0 0-.826.352l-3.392 3.454a1.232 1.232 0 0 0-.387.896c0 .343.132.642.387.905l3.392 3.437c.211.22.492.334.826.334Z"
              fill="#fff"
            />
          </svg>
        </button>
        <AreaChartDropdown />
      </div>
    </div>
  );
};

export default ChartHeader;
