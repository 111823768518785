import { PropsWithChildren } from 'react';
import cx from 'classnames';

interface TableSecondaryProps {
  className?: string;
}

interface TrProps {
  className?: string;
}

interface ThProps {
  className?: string;
}

interface TdProps {
  className?: string;
}

interface TheadProps {
  className?: string;
}

interface TbodyProps {
  className?: string;
}

export const Thead = ({ children, className }: PropsWithChildren<TheadProps>) => (
  <thead className={cx('', className)}>{children}</thead>
);

export const Tbody = ({ children, className }: PropsWithChildren<TbodyProps>) => (
  <tbody className={cx('', className)}>{children}</tbody>
);

export const Tr = ({ children, className }: PropsWithChildren<TrProps>) => (
  <tr className={cx('', className)}>{children}</tr>
);

export const Th = ({ children, className }: PropsWithChildren<ThProps>) => (
  <th
    className={cx(
      'pb-4 pt-6 px-4 text-pikachu font-bold uppercase tracking-widest text-white',
      className,
    )}
  >
    {children}
  </th>
);

export const Td = ({ children, className }: PropsWithChildren<TdProps>) => (
  <td className={cx('p-4 text-white', className)}>{children}</td>
);

export default function TableSecondary({
  className,
  children,
}: PropsWithChildren<TableSecondaryProps>) {
  return <table className={cx('rounded-3xl bg-primary-stoneBlue', className)}>{children}</table>;
}
