import { gql } from '@apollo/client';

export const GET_OVERVIEW_QUERY = gql`
  query GetOverview(
    $walletId: String!
    $walletCollectionsOffset: Int!
    $walletCollectionsLimit: Int!
  ) {
    walletSummary(wallet: $walletId) {
      totalValue {
        ethValue
        fiatValue
      }
      totalAssets
      totalGasSpent {
        ethValue
        fiatValue
      }
      ownedCollections {
        name
        count
      }
      totalValueChange {
        percent
      }
    }

    walletCollections(
      wallet: $walletId
      offset: $walletCollectionsOffset
      limit: $walletCollectionsLimit
      orderBy: BY_DAILY_CHANGE
    ) {
      nodes {
        id
        name
        imageUrl
        assetCount
        stats {
          floorPrice {
            ethValue
          }
          dailyChange {
            percent
          }
        }
      }
    }

    walletTotalValueHistory(wallet: $walletId) {
      interval
      values {
        date
        value {
          ethValue
          fiatValue
        }
      }
    }
  }
`;
