import ChangeIndicator from '../ChangeIndicator';

interface WatchedCollectionsTableRowProps {
  userOwnedItemCount?: number;
  collectionImageUrl?: string;
  collectionName?: string;
  supplyCount?: string;
  floor?: string;
  floorChange?: number;
  volume?: string;
  volumeChange?: number;
  holders?: string;
  holdersChange?: number;
}

export default function WatchedCollectionsTableRow({
  userOwnedItemCount,
  collectionImageUrl,
  collectionName,
  supplyCount,
  floor,
  floorChange,
  volume,
  volumeChange,
  holders,
  holdersChange,
}: WatchedCollectionsTableRowProps) {
  return (
    <tr>
      <td className="p-6">
        <div className="flex items-center">
          <div className="w-[72px] h-[72px] rounded-xl overflow-hidden">
            <img
              src={collectionImageUrl}
              alt={collectionName}
              width={72}
              height={72}
              className="object-cover"
            />
          </div>
          <div className="ml-4">
            {!!userOwnedItemCount && (
              <div className="font-medium text-pikachu tracking-wider text-custom-lemon uppercase">
                You have {userOwnedItemCount} items
              </div>
            )}
            <div className="font-medium text-primary-polar mt-1">{collectionName}</div>
            <div className="mt-1 font-bold text-diglett text-primary-casper uppercase">
              {supplyCount} supply
            </div>
          </div>
        </div>
      </td>
      <td className="p-6 align-middle">
        <div>
          <div className="font-montserrat font-bold text-charmeleon text-primary-polar">
            {floor}
            <ChangeIndicator className="mt-1" change={floorChange} />
          </div>
        </div>
      </td>
      <td className="p-6 align-middle">
        <div>
          <div className="font-montserrat font-bold text-charmeleon text-primary-polar">
            {volume}
            <ChangeIndicator className="mt-1" change={volumeChange} />
          </div>
        </div>
      </td>
      <td className="p-6 align-middle">
        <div>
          <div className="font-montserrat font-bold text-charmeleon text-primary-polar">
            {holders}
            <ChangeIndicator className="mt-1" change={holdersChange} />
          </div>
        </div>
      </td>
    </tr>
  );
}
