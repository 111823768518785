import { motion } from 'framer-motion';
import { useState } from 'react';

interface SocialBtnProps {
  btnText: string;
  btnLink?: string;
  className?: string;
  btnIcon: any;
  hoverBg?: string;
  hoverTextColor?: string;
}

const SocialBtn = ({
  btnLink,
  btnText,
  btnIcon,
  hoverBg,
  hoverTextColor,
  className,
}: SocialBtnProps) => {
  const [isHover, setIsHover] = useState(false);

  const btnVariants = {
    initial: {
      width: '3.5rem',
    },
    hover: {
      width: '10rem',
      backgroundColor: hoverBg,
      color: hoverTextColor,
      transition: { duration: 0.3, ease: 'easeOut' },
    },
  };

  return (
    <motion.div
      initial="initial"
      animate=""
      whileHover="hover"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className="overflow-hidden"
    >
      <motion.a
        variants={btnVariants}
        href={btnLink}
        target="_blank"
        rel="noreferrer"
        className="flex flex-row items-center justify-start space-x-4 rounded-2xl bg-primary-stoneBlue p-4 font-montserrat text-squirtle font-semibold uppercase tracking-widest text-primary-casper"
      >
        <motion.span>{btnIcon}</motion.span>
        {isHover && <motion.span>{btnText}</motion.span>}
      </motion.a>
    </motion.div>
  );
};

export default SocialBtn;
