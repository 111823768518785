import { ReactNode } from 'react';

interface WatchedWalletsTableRowProps {
  walletName?: string;
  walletImageUrl?: string;
  transaction?: string;
  collectionName?: string;
  assetImageUrl?: string;
  assetToken?: string;
  transactionType?: ReactNode;
  transactionTime?: string;
  amount?: string;
}

export default function WatchedWalletsTableRow({
  walletName,
  walletImageUrl,
  transaction,
  collectionName,
  assetImageUrl,
  assetToken,
  transactionType,
  transactionTime,
  amount,
}: WatchedWalletsTableRowProps) {
  return (
    <tr>
      <td className="py-8 px-6">
        <div className="flex items-center">
          <div className="w-10 h-10 rounded-lg overflow-hidden">
            <img
              src={walletImageUrl}
              alt={walletName}
              width={40}
              height={40}
              className="object-cover"
            />
          </div>
          <div className="ml-4">
            <div className="font-medium text-squirtle text-primary-polar">{walletName}</div>
            <div className="text-squirtle text-primary-casper mt-1">{transaction}</div>
          </div>
        </div>
      </td>
      <td className="py-8 px-6">
        <div className="flex items-center">
          <div className="w-10 h-10 rounded-md overflow-hidden">
            <img
              src={assetImageUrl}
              alt={assetToken}
              width={40}
              height={40}
              className="object-cover"
            />
          </div>
          <div className="ml-2">
            <div className="font-medium text-squirtle text-primary-polar">{collectionName}</div>
            <div className="text-custom-lemon font-medium text-pikachu mt-1">{assetToken}</div>
          </div>
        </div>
      </td>
      <td className="py-8 px-6">
        <div>
          <div>{transactionType}</div>
          <div className="text-squirtle text-primary-casper">{transactionTime}</div>
        </div>
      </td>
      <td className="py-8 px-6 align-middle">
        <div className="text-primary-casper text-charmeleon">{amount}</div>
      </td>
    </tr>
  );
}
