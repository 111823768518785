import gql from 'graphql-tag';

export const CollectionTableFields = gql`
  fragment CollectionTableFields on Collection {
    id
    name
    imageUrl
    assetCount
    assets {
      id
      tokenId
      name
      imageUrl
      openseaLink
      etherscanLink
      traits {
        type
        value
        rarity
        floorPrice
      }
    }
    stats {
      floorPrice {
        ethValue
      }
      dailyChange {
        percent
      }
      weeklyChange {
        percent
      }
      monthlyChange {
        percent
      }
    }
    openseaLink
    etherscanLink
  }
`;
