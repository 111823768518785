import cx from 'classnames';
import { PropsWithChildren } from 'react';
import { useMoralis } from 'react-moralis';

import Header from '../Header';
import NoAccess from '../NoAccess';
import Sidebar from '../Sidebar/Sidebar';

interface LayoutProps {
  className?: string;
}

export default function Layout({ className, children }: PropsWithChildren<LayoutProps>) {
  const { isAuthenticated } = useMoralis();

  return (
    <div className={cx('flex h-screen overflow-hidden', className)}>
      <Sidebar />
      <div className="flex flex-grow flex-col">
        <Header className="flex-shrink-0" />
        <div className="flex-grow overflow-auto">
          {isAuthenticated ? (
            children
          ) : (
            <div className="flex h-full items-center justify-center p-12">
              <NoAccess />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
