import cx from 'classnames';

import { ReactComponent as SoldIcon } from '../assets/icons/sold.svg';
import { ReactComponent as BoughtIcon } from '../assets/icons/bought.svg';
import { ReactComponent as TransferIcon } from '../assets/icons/transfer.svg';

type Transaction = 'sold' | 'received' | 'bought' | 'sent' | 'minted';

export interface TransactionTypeProps {
  className?: string;
  transaction: Transaction;
}

function getItem(transaction: Transaction) {
  switch (transaction) {
    case 'sold':
      return (
        <div className="flex items-center text-pikachu font-semibold text-custom-crimson">
          <SoldIcon className="mr-[2px]" />
          Sold
        </div>
      );
    case 'received':
      return (
        <div className="flex items-center text-pikachu font-semibold text-custom-lemon">
          <TransferIcon className="mr-[2px]" />
          Received
        </div>
      );
    case 'bought':
      return (
        <div className="flex items-center text-pikachu font-semibold text-custom-spring">
          <BoughtIcon className="mr-[2px]" />
          Bought
        </div>
      );
    case 'sent':
      return (
        <div className="flex items-center text-pikachu font-semibold text-custom-lemon">
          <TransferIcon className="mr-[2px]" />
          Sent
        </div>
      );
    case 'minted':
      return (
        <div className="flex items-center text-pikachu font-semibold text-custom-lemon">Minted</div>
      );
  }
}

export default function TransactionType({ className, transaction }: TransactionTypeProps) {
  return <div className={cx('', className)}>{getItem(transaction)}</div>;
}
