import { motion } from 'framer-motion';
import SubmitButton from '../../components/Buttons/SubmitButton';
import Input from '../../components/Input';
import MostActiveWalletsTable, {
  MostActiveWalletsTableRow,
} from '../../components/MostActiveWalletsTable';
import NewCollectionsTable from '../../components/NewCollectionsTable';
import Panel from '../../components/Panel';

import TransactionType from '../../components/TransactionType';
import WatchedCollectionsTable, {
  WatchedCollectionsTableRow,
} from '../../components/WatchedCollectionsTable';
import WatchedWalletsTable, { WatchedWalletsTableRow } from '../../components/WatchedWalletsTable';

import './Watchlist.css';

export default function Watchlist() {
  return (
    <div className="watchlist flex-grow">
      <div className="container">
        <motion.div
          initial={{ y: -40, opacity: 0 }}
          animate={{ y: 0, opacity: 1, transition: { duration: 0.5 } }}
          className="watchlist__container"
        >
          <div>
            <WatchedWalletsTable>
              <WatchedWalletsTableRow
                walletName="Punks Whale"
                walletImageUrl="https://s3-alpha-sig.figma.com/img/d6a2/20dc/0c543d5281a5710503aafaa3d5f17bb2?Expires=1639958400&Signature=NdMuJmNzEomecnYzdOEqkizt6uDKjW1FeT2JWze0yLan-YwIVf9daLT5JI~ZiXJVo7yYVn1f1ZEvguJG9wb1FojpDyrNy~fcUg8866ExNlG2LpnZuC3NR0RF6NqJEsKm565QpXdqFPKnenPNfFscuZqEzoUsRIVlEDeyWKR8OeuhPtl6YhdNuOniwWwR62Db58EelB3H8oiNU-5yblqF9Bb40L9UvCYpZkZ7yLMZtVBF~y6xMaRPWEbF9QP6XWFgjcoNOOqKWbgXkFAruZ-lZq0PTzRGatoZzAmbh9t3psNXkpRp0PFUTsj88POQ7iG0frmlsPqKLO3Rt~R9toXQbA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                transaction="from: 0x34...4Ef6"
                collectionName="Mutant Ape Yacht Club"
                assetImageUrl="https://s3-alpha-sig.figma.com/img/5c82/1f14/15e2eecefc876a6c93642216f2959ebf?Expires=1639958400&Signature=NaWfFsC14Q~bqdyj5ko1yYWu5Fz4rKCOKiTX-CaLhDHKdC5Ui-zutHICstC0a3N1ucXK0-tn0UxRONH-uKASMIISGCtit4MfOOEu7c2XQ2EuICvp0PSUf2UWq8RohJcbtUGoCGkk1iiQVbjowawEkgTLYsDPcofGf6dACFUnghqYKpuEm2s0KHHwNFkcuvjubNb71IIKcn03JJ-9Os~RkA1Kxebvgq2ZJ2WmYRQnc1bgp3CEk65vMnXQz8e6-eVLo3WRsownIzzEH1gmdMR~Jn6AAR3bKTLaUzjnE1jqXhm59snzkp1JRBppIrGze8cGWAhlG0XJvUcHpc3tmpxoqw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                assetToken="#842"
                transactionType={<TransactionType transaction="bought" />}
                transactionTime="just now"
                amount="Ξ 149"
              />
              <WatchedWalletsTableRow
                walletName="Punks Whale"
                walletImageUrl="https://s3-alpha-sig.figma.com/img/d6a2/20dc/0c543d5281a5710503aafaa3d5f17bb2?Expires=1639958400&Signature=NdMuJmNzEomecnYzdOEqkizt6uDKjW1FeT2JWze0yLan-YwIVf9daLT5JI~ZiXJVo7yYVn1f1ZEvguJG9wb1FojpDyrNy~fcUg8866ExNlG2LpnZuC3NR0RF6NqJEsKm565QpXdqFPKnenPNfFscuZqEzoUsRIVlEDeyWKR8OeuhPtl6YhdNuOniwWwR62Db58EelB3H8oiNU-5yblqF9Bb40L9UvCYpZkZ7yLMZtVBF~y6xMaRPWEbF9QP6XWFgjcoNOOqKWbgXkFAruZ-lZq0PTzRGatoZzAmbh9t3psNXkpRp0PFUTsj88POQ7iG0frmlsPqKLO3Rt~R9toXQbA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                transaction="from: 0x34...4Ef6"
                collectionName="Mutant Ape Yacht Club"
                assetImageUrl="https://s3-alpha-sig.figma.com/img/5c82/1f14/15e2eecefc876a6c93642216f2959ebf?Expires=1639958400&Signature=NaWfFsC14Q~bqdyj5ko1yYWu5Fz4rKCOKiTX-CaLhDHKdC5Ui-zutHICstC0a3N1ucXK0-tn0UxRONH-uKASMIISGCtit4MfOOEu7c2XQ2EuICvp0PSUf2UWq8RohJcbtUGoCGkk1iiQVbjowawEkgTLYsDPcofGf6dACFUnghqYKpuEm2s0KHHwNFkcuvjubNb71IIKcn03JJ-9Os~RkA1Kxebvgq2ZJ2WmYRQnc1bgp3CEk65vMnXQz8e6-eVLo3WRsownIzzEH1gmdMR~Jn6AAR3bKTLaUzjnE1jqXhm59snzkp1JRBppIrGze8cGWAhlG0XJvUcHpc3tmpxoqw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                assetToken="#842"
                transactionType={<TransactionType transaction="bought" />}
                transactionTime="just now"
                amount="Ξ 149"
              />
              <WatchedWalletsTableRow
                walletName="Punks Whale"
                walletImageUrl="https://s3-alpha-sig.figma.com/img/d6a2/20dc/0c543d5281a5710503aafaa3d5f17bb2?Expires=1639958400&Signature=NdMuJmNzEomecnYzdOEqkizt6uDKjW1FeT2JWze0yLan-YwIVf9daLT5JI~ZiXJVo7yYVn1f1ZEvguJG9wb1FojpDyrNy~fcUg8866ExNlG2LpnZuC3NR0RF6NqJEsKm565QpXdqFPKnenPNfFscuZqEzoUsRIVlEDeyWKR8OeuhPtl6YhdNuOniwWwR62Db58EelB3H8oiNU-5yblqF9Bb40L9UvCYpZkZ7yLMZtVBF~y6xMaRPWEbF9QP6XWFgjcoNOOqKWbgXkFAruZ-lZq0PTzRGatoZzAmbh9t3psNXkpRp0PFUTsj88POQ7iG0frmlsPqKLO3Rt~R9toXQbA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                transaction="from: 0x34...4Ef6"
                collectionName="Mutant Ape Yacht Club"
                assetImageUrl="https://s3-alpha-sig.figma.com/img/5c82/1f14/15e2eecefc876a6c93642216f2959ebf?Expires=1639958400&Signature=NaWfFsC14Q~bqdyj5ko1yYWu5Fz4rKCOKiTX-CaLhDHKdC5Ui-zutHICstC0a3N1ucXK0-tn0UxRONH-uKASMIISGCtit4MfOOEu7c2XQ2EuICvp0PSUf2UWq8RohJcbtUGoCGkk1iiQVbjowawEkgTLYsDPcofGf6dACFUnghqYKpuEm2s0KHHwNFkcuvjubNb71IIKcn03JJ-9Os~RkA1Kxebvgq2ZJ2WmYRQnc1bgp3CEk65vMnXQz8e6-eVLo3WRsownIzzEH1gmdMR~Jn6AAR3bKTLaUzjnE1jqXhm59snzkp1JRBppIrGze8cGWAhlG0XJvUcHpc3tmpxoqw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                assetToken="#842"
                transactionType={<TransactionType transaction="bought" />}
                transactionTime="just now"
                amount="Ξ 149"
              />
              <WatchedWalletsTableRow
                walletName="Punks Whale"
                walletImageUrl="https://s3-alpha-sig.figma.com/img/d6a2/20dc/0c543d5281a5710503aafaa3d5f17bb2?Expires=1639958400&Signature=NdMuJmNzEomecnYzdOEqkizt6uDKjW1FeT2JWze0yLan-YwIVf9daLT5JI~ZiXJVo7yYVn1f1ZEvguJG9wb1FojpDyrNy~fcUg8866ExNlG2LpnZuC3NR0RF6NqJEsKm565QpXdqFPKnenPNfFscuZqEzoUsRIVlEDeyWKR8OeuhPtl6YhdNuOniwWwR62Db58EelB3H8oiNU-5yblqF9Bb40L9UvCYpZkZ7yLMZtVBF~y6xMaRPWEbF9QP6XWFgjcoNOOqKWbgXkFAruZ-lZq0PTzRGatoZzAmbh9t3psNXkpRp0PFUTsj88POQ7iG0frmlsPqKLO3Rt~R9toXQbA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                transaction="from: 0x34...4Ef6"
                collectionName="Mutant Ape Yacht Club"
                assetImageUrl="https://s3-alpha-sig.figma.com/img/5c82/1f14/15e2eecefc876a6c93642216f2959ebf?Expires=1639958400&Signature=NaWfFsC14Q~bqdyj5ko1yYWu5Fz4rKCOKiTX-CaLhDHKdC5Ui-zutHICstC0a3N1ucXK0-tn0UxRONH-uKASMIISGCtit4MfOOEu7c2XQ2EuICvp0PSUf2UWq8RohJcbtUGoCGkk1iiQVbjowawEkgTLYsDPcofGf6dACFUnghqYKpuEm2s0KHHwNFkcuvjubNb71IIKcn03JJ-9Os~RkA1Kxebvgq2ZJ2WmYRQnc1bgp3CEk65vMnXQz8e6-eVLo3WRsownIzzEH1gmdMR~Jn6AAR3bKTLaUzjnE1jqXhm59snzkp1JRBppIrGze8cGWAhlG0XJvUcHpc3tmpxoqw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                assetToken="#842"
                transactionType={<TransactionType transaction="bought" />}
                transactionTime="just now"
                amount="Ξ 149"
              />
            </WatchedWalletsTable>
            <WatchedCollectionsTable className="mt-6">
              <WatchedCollectionsTableRow
                userOwnedItemCount={4}
                collectionImageUrl="https://s3-alpha-sig.figma.com/img/5e75/3b9e/edc44a44eee13e7a763627a017aa2b12?Expires=1639958400&Signature=b4aZ9FrR2qq-4AE312p1NTkeCNamllGiLnFBwf1ESa73T91WRYu0EWJNxqwf5kKOJkQ~9Hj1NFjqxF~~zgaZOQJN46Bwe5DfNERSbFGlb8BxL1FGgJMAGesh3MDQ5e4slmoYN83ySuwyKY20h3TMV4ZVYJp8cZCfqoKzcUjrpubM29X0VqMFyO45oLiBGRPhZNKC7sxRiKhwMbcQBscqfBr6Cotl1Zry6kerf~duB2NIHTweZEokGl8e1JcfzXL2-NUYiC0PF0CynvTyHFDsqWgIv-elrRBe00FqiP4bXXFAsKzphicxHOHmHUdb2o07WyYh0VmRpjg7ahlZhPQoYg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                collectionName="Chain Runners"
                supplyCount="8.888"
                floor="Ξ 6.9"
                floorChange={12}
                volume="Ξ 104"
                volumeChange={-12}
                holders="2.5k"
                holdersChange={12}
              />
              <WatchedCollectionsTableRow
                userOwnedItemCount={4}
                collectionImageUrl="https://s3-alpha-sig.figma.com/img/5e75/3b9e/edc44a44eee13e7a763627a017aa2b12?Expires=1639958400&Signature=b4aZ9FrR2qq-4AE312p1NTkeCNamllGiLnFBwf1ESa73T91WRYu0EWJNxqwf5kKOJkQ~9Hj1NFjqxF~~zgaZOQJN46Bwe5DfNERSbFGlb8BxL1FGgJMAGesh3MDQ5e4slmoYN83ySuwyKY20h3TMV4ZVYJp8cZCfqoKzcUjrpubM29X0VqMFyO45oLiBGRPhZNKC7sxRiKhwMbcQBscqfBr6Cotl1Zry6kerf~duB2NIHTweZEokGl8e1JcfzXL2-NUYiC0PF0CynvTyHFDsqWgIv-elrRBe00FqiP4bXXFAsKzphicxHOHmHUdb2o07WyYh0VmRpjg7ahlZhPQoYg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                collectionName="Chain Runners"
                supplyCount="8.888"
                floor="Ξ 6.9"
                floorChange={12}
                volume="Ξ 104"
                volumeChange={-12}
                holders="2.5k"
                holdersChange={12}
              />
              <WatchedCollectionsTableRow
                userOwnedItemCount={4}
                collectionImageUrl="https://s3-alpha-sig.figma.com/img/5e75/3b9e/edc44a44eee13e7a763627a017aa2b12?Expires=1639958400&Signature=b4aZ9FrR2qq-4AE312p1NTkeCNamllGiLnFBwf1ESa73T91WRYu0EWJNxqwf5kKOJkQ~9Hj1NFjqxF~~zgaZOQJN46Bwe5DfNERSbFGlb8BxL1FGgJMAGesh3MDQ5e4slmoYN83ySuwyKY20h3TMV4ZVYJp8cZCfqoKzcUjrpubM29X0VqMFyO45oLiBGRPhZNKC7sxRiKhwMbcQBscqfBr6Cotl1Zry6kerf~duB2NIHTweZEokGl8e1JcfzXL2-NUYiC0PF0CynvTyHFDsqWgIv-elrRBe00FqiP4bXXFAsKzphicxHOHmHUdb2o07WyYh0VmRpjg7ahlZhPQoYg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                collectionName="Chain Runners"
                supplyCount="8.888"
                floor="Ξ 6.9"
                floorChange={12}
                volume="Ξ 104"
                volumeChange={-12}
                holders="2.5k"
                holdersChange={12}
              />
              <WatchedCollectionsTableRow
                userOwnedItemCount={4}
                collectionImageUrl="https://s3-alpha-sig.figma.com/img/5e75/3b9e/edc44a44eee13e7a763627a017aa2b12?Expires=1639958400&Signature=b4aZ9FrR2qq-4AE312p1NTkeCNamllGiLnFBwf1ESa73T91WRYu0EWJNxqwf5kKOJkQ~9Hj1NFjqxF~~zgaZOQJN46Bwe5DfNERSbFGlb8BxL1FGgJMAGesh3MDQ5e4slmoYN83ySuwyKY20h3TMV4ZVYJp8cZCfqoKzcUjrpubM29X0VqMFyO45oLiBGRPhZNKC7sxRiKhwMbcQBscqfBr6Cotl1Zry6kerf~duB2NIHTweZEokGl8e1JcfzXL2-NUYiC0PF0CynvTyHFDsqWgIv-elrRBe00FqiP4bXXFAsKzphicxHOHmHUdb2o07WyYh0VmRpjg7ahlZhPQoYg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                collectionName="Chain Runners"
                supplyCount="8.888"
                floor="Ξ 6.9"
                floorChange={12}
                volume="Ξ 104"
                volumeChange={-12}
                holders="2.5k"
                holdersChange={12}
              />
              <WatchedCollectionsTableRow
                userOwnedItemCount={4}
                collectionImageUrl="https://s3-alpha-sig.figma.com/img/5e75/3b9e/edc44a44eee13e7a763627a017aa2b12?Expires=1639958400&Signature=b4aZ9FrR2qq-4AE312p1NTkeCNamllGiLnFBwf1ESa73T91WRYu0EWJNxqwf5kKOJkQ~9Hj1NFjqxF~~zgaZOQJN46Bwe5DfNERSbFGlb8BxL1FGgJMAGesh3MDQ5e4slmoYN83ySuwyKY20h3TMV4ZVYJp8cZCfqoKzcUjrpubM29X0VqMFyO45oLiBGRPhZNKC7sxRiKhwMbcQBscqfBr6Cotl1Zry6kerf~duB2NIHTweZEokGl8e1JcfzXL2-NUYiC0PF0CynvTyHFDsqWgIv-elrRBe00FqiP4bXXFAsKzphicxHOHmHUdb2o07WyYh0VmRpjg7ahlZhPQoYg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                collectionName="Chain Runners"
                supplyCount="8.888"
                floor="Ξ 6.9"
                floorChange={12}
                volume="Ξ 104"
                volumeChange={-12}
                holders="2.5k"
                holdersChange={12}
              />
            </WatchedCollectionsTable>
          </div>
          <div>
            <Panel className="py-6 px-4">
              <h2 className="text-primary-polar font-montserrat font-bold text-charmeleon">
                Add a new address to Watchlist
              </h2>
              <Input className="mt-4" placeholder="Give it a name" />
              <Input className="mt-4" placeholder="Add collection or wallet address" />
              <SubmitButton className="mt-4 flex items-center justify-center w-full">
                Add to watchlist
                <svg
                  className="ml-2"
                  width="20"
                  height="20"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.42 18.333c4.57 0 8.33-3.76 8.33-8.33 0-4.577-3.76-8.337-8.337-8.337-4.578 0-8.33 3.76-8.33 8.337 0 4.57 3.76 8.33 8.338 8.33ZM6.59 9.995c0-.542.394-.951.936-.951H9.47V7.1c0-.535.401-.936.944-.936.543 0 .952.401.952.936v1.943h1.942c.535 0 .936.409.936.951a.916.916 0 0 1-.936.944h-1.942v1.95c0 .536-.41.929-.952.929-.543 0-.944-.393-.944-.928v-1.95H7.526c-.542 0-.936-.402-.936-.945Z" />
                </svg>
              </SubmitButton>
            </Panel>
            <MostActiveWalletsTable className="mt-6">
              <MostActiveWalletsTableRow
                order={1}
                walletImageUrl="https://s3-alpha-sig.figma.com/img/d6a2/20dc/0c543d5281a5710503aafaa3d5f17bb2?Expires=1639958400&Signature=NdMuJmNzEomecnYzdOEqkizt6uDKjW1FeT2JWze0yLan-YwIVf9daLT5JI~ZiXJVo7yYVn1f1ZEvguJG9wb1FojpDyrNy~fcUg8866ExNlG2LpnZuC3NR0RF6NqJEsKm565QpXdqFPKnenPNfFscuZqEzoUsRIVlEDeyWKR8OeuhPtl6YhdNuOniwWwR62Db58EelB3H8oiNU-5yblqF9Bb40L9UvCYpZkZ7yLMZtVBF~y6xMaRPWEbF9QP6XWFgjcoNOOqKWbgXkFAruZ-lZq0PTzRGatoZzAmbh9t3psNXkpRp0PFUTsj88POQ7iG0frmlsPqKLO3Rt~R9toXQbA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                walletName="Punks Whale"
                address="0x34...4Ef6"
                txCount={48}
              />
              <MostActiveWalletsTableRow
                order={1}
                walletImageUrl="https://s3-alpha-sig.figma.com/img/d6a2/20dc/0c543d5281a5710503aafaa3d5f17bb2?Expires=1639958400&Signature=NdMuJmNzEomecnYzdOEqkizt6uDKjW1FeT2JWze0yLan-YwIVf9daLT5JI~ZiXJVo7yYVn1f1ZEvguJG9wb1FojpDyrNy~fcUg8866ExNlG2LpnZuC3NR0RF6NqJEsKm565QpXdqFPKnenPNfFscuZqEzoUsRIVlEDeyWKR8OeuhPtl6YhdNuOniwWwR62Db58EelB3H8oiNU-5yblqF9Bb40L9UvCYpZkZ7yLMZtVBF~y6xMaRPWEbF9QP6XWFgjcoNOOqKWbgXkFAruZ-lZq0PTzRGatoZzAmbh9t3psNXkpRp0PFUTsj88POQ7iG0frmlsPqKLO3Rt~R9toXQbA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                walletName="Punks Whale"
                address="0x34...4Ef6"
                txCount={48}
              />
              <MostActiveWalletsTableRow
                order={1}
                walletImageUrl="https://s3-alpha-sig.figma.com/img/d6a2/20dc/0c543d5281a5710503aafaa3d5f17bb2?Expires=1639958400&Signature=NdMuJmNzEomecnYzdOEqkizt6uDKjW1FeT2JWze0yLan-YwIVf9daLT5JI~ZiXJVo7yYVn1f1ZEvguJG9wb1FojpDyrNy~fcUg8866ExNlG2LpnZuC3NR0RF6NqJEsKm565QpXdqFPKnenPNfFscuZqEzoUsRIVlEDeyWKR8OeuhPtl6YhdNuOniwWwR62Db58EelB3H8oiNU-5yblqF9Bb40L9UvCYpZkZ7yLMZtVBF~y6xMaRPWEbF9QP6XWFgjcoNOOqKWbgXkFAruZ-lZq0PTzRGatoZzAmbh9t3psNXkpRp0PFUTsj88POQ7iG0frmlsPqKLO3Rt~R9toXQbA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                walletName="Punks Whale"
                address="0x34...4Ef6"
                txCount={48}
              />
              <MostActiveWalletsTableRow
                order={1}
                walletImageUrl="https://s3-alpha-sig.figma.com/img/d6a2/20dc/0c543d5281a5710503aafaa3d5f17bb2?Expires=1639958400&Signature=NdMuJmNzEomecnYzdOEqkizt6uDKjW1FeT2JWze0yLan-YwIVf9daLT5JI~ZiXJVo7yYVn1f1ZEvguJG9wb1FojpDyrNy~fcUg8866ExNlG2LpnZuC3NR0RF6NqJEsKm565QpXdqFPKnenPNfFscuZqEzoUsRIVlEDeyWKR8OeuhPtl6YhdNuOniwWwR62Db58EelB3H8oiNU-5yblqF9Bb40L9UvCYpZkZ7yLMZtVBF~y6xMaRPWEbF9QP6XWFgjcoNOOqKWbgXkFAruZ-lZq0PTzRGatoZzAmbh9t3psNXkpRp0PFUTsj88POQ7iG0frmlsPqKLO3Rt~R9toXQbA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                walletName="Punks Whale"
                address="0x34...4Ef6"
                txCount={48}
              />
            </MostActiveWalletsTable>
            <NewCollectionsTable className="mt-6" />
          </div>
        </motion.div>
      </div>
    </div>
  );
}
