import cx from 'classnames';

interface SidebarShrinkButtonProps {
  className?: string;
  minimized: boolean;
  onClick?: () => void;
}

export default function SidebarShrinkButton({
  className,
  minimized,
  onClick,
}: SidebarShrinkButtonProps) {
  return (
    <button className={cx('', className)} type="button" onClick={onClick}>
      {minimized ? (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 57 89">
          <path
            fill="#000F1D"
            d="M0 44.5C0 73 28.5 68.5 28.5 89 44.24 89 57 60.24 57 44.5 57 28.76 44.24 0 28.5 0 28.5 20.5 0 16 0 44.5Z"
          />
          <path
            fill="#8BA2B2"
            d="M31.652 45.54c0 .608-.21 1.112-.726 1.605l-8.813 8.624c-.375.376-.82.551-1.347.551-1.09 0-1.97-.879-1.97-1.945 0-.55.224-1.043.634-1.441l7.64-7.407-7.64-7.383c-.41-.41-.633-.902-.633-1.44 0-1.067.879-1.946 1.969-1.946.527 0 .972.187 1.347.55l8.813 8.626c.504.492.726.984.726 1.605Z"
          />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 57 89">
          <path
            fill="#000F1D"
            d="M0 44.5C0 73 28.5 68.5 28.5 89 44.24 89 57 60.24 57 44.5 57 28.76 44.24 0 28.5 0 28.5 20.5 0 16 0 44.5Z"
          />
          <path
            fill="#8BA2B2"
            d="M16.348 45.54c0 .608.21 1.112.726 1.605l8.813 8.624c.375.376.82.551 1.347.551 1.09 0 1.97-.879 1.97-1.945 0-.55-.224-1.043-.634-1.441l-7.64-7.407 7.64-7.383c.41-.41.633-.902.633-1.44 0-1.067-.879-1.946-1.969-1.946-.527 0-.972.187-1.347.55l-8.813 8.626c-.504.492-.726.984-.726 1.605Z"
          />
        </svg>
      )}
    </button>
  );
}
