import cx from 'classnames';
import { PropsWithChildren } from 'react';

interface PanelProps {
  className?: string;
  radius?: 'default' | 'small';
}

const radiusByProp = {
  default: 'rounded-3xl',
  small: 'rounded-2xl',
};

export default function Panel({
  className,
  children,
  radius = 'default',
}: PropsWithChildren<PanelProps>) {
  return (
    <div
      className={cx(
        'bg-primary-stoneBlue border border-primary-woodBlue overflow-hidden',
        radiusByProp[radius],
        className,
      )}
    >
      {children}
    </div>
  );
}
