import { useEffect } from 'react';
import { useMoralis, useChain } from 'react-moralis';
import cx from 'classnames';
import toast from '../Toast';

import Search from '../Search';
import ConnectBtn from '../Buttons/ConnectBtn';
import GhostBtn from '../Buttons/GhostBtn';
import WalletBtn from '../Buttons/WalletBtn';
import { shrinkAddress } from '../../utils/shrinkAddress';

interface HeaderProps {
  className?: string;
}

const NoAccessToast = ({ address }: { address?: string }) => {
  return (
    <div className="max-w-xs">
      <div className="font-montserrat text-pikachu font-normal text-primary-polar">{address}</div>
      <div className="mt-2 font-montserrat text-squirtle font-bold text-primary-polar">
        You don’t have access to Lava Tools <br />
        <span className="text-custom-crimson">Private Alpha</span>
      </div>
      <div className="mt-2 font-montserrat text-pikachu font-normal">
        <a
          href="https://discord.gg/SmVe8BpX"
          target="_blank"
          rel="noreferrer"
          className="font-bold text-custom-aqua underline"
        >
          Join our discord
        </a>{' '}
        to have a chance to get in the beta testing!
      </div>
    </div>
  );
};

const Header = ({ className }: HeaderProps) => {
  const { authenticate, isAuthenticated, logout, user, authError, userError, Moralis } =
    useMoralis();

  const { account } = useChain();

  const userAcc = isAuthenticated ? user.get('ethAddress') : null;
  const sessionToken = isAuthenticated ? user.get('sessionToken') : null;

  useEffect(() => {
    Moralis.Web3.onAccountsChanged(() => {
      authenticate({ signingMessage: 'Log in to LavaTools relogin' });
    });
  }, [Moralis.Web3, authenticate]);

  useEffect(() => {
    if (authError || userError) {
      toast(<NoAccessToast address={shrinkAddress(account)} />, { id: 'auth-error' });
    }
  }, [authError, userError, account]);

  useEffect(() => {
    if (isAuthenticated) {
      window.localStorage.setItem('sessionToken', sessionToken);
    }
  }, [isAuthenticated, sessionToken]);

  function handleConnect() {
    if (!window.ethereum) {
      return toast('Please install metamask to log in', {
        id: 'install-metamask',
      });
    }

    authenticate({ signingMessage: 'Log in to LavaTools' });
  }

  return (
    <div
      className={cx(
        'h-[104px] flex items-center justify-between border-b border-primary-woodBlue bg-primary-carbonBlue pl-12 pr-10',
        className,
      )}
    >
      <Search />
      <div className="flex items-center space-x-8">
        <GhostBtn btnText="Roadmap" btnLink="https://roadmap.lavatools.io" />
        {isAuthenticated ? (
          <WalletBtn
            onClick={() => logout().then(() => window.localStorage.removeItem('sessionToken'))}
          >
            {shrinkAddress(userAcc)}
          </WalletBtn>
        ) : (
          <ConnectBtn onClick={handleConnect} />
        )}
      </div>
    </div>
  );
};

export default Header;
