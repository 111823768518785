import { ComponentProps } from 'react';
import cx from 'classnames';

interface InputProps extends ComponentProps<'input'> {
  className?: string;
}

export default function Input({ className, ...otherProps }: InputProps) {
  return (
    <input
      className={cx(
        'block w-full py-3 px-4 border-transparent border bg-primary-woodBlue rounded-2xl text-primary-casper placeholder-primary-casper focus:text-primary-casper focus:bg-custom-valentino focus:border focus:border-primary-flamingo focus:outline-none',
        className,
      )}
      {...otherProps}
    />
  );
}
