import cx from 'classnames';

import './CollectionHeader.css';

interface CollectionHeaderProps {
  className?: string;
  coverImgUrl?: string;
  name: string;
  avatarImgUrl: string;
  floorPrice: string;
  assetCount: string;
  ownerCount: string;
  volume: string;
}

export default function CollectionHeader({
  className,
  coverImgUrl,
  name,
  avatarImgUrl,
  floorPrice,
  assetCount,
  ownerCount,
  volume,
}: CollectionHeaderProps) {
  return (
    <div
      className={cx('collectionHeader pl-12 pr-16 py-10 ', className)}
      style={{ backgroundImage: `url('${coverImgUrl}')` }}
    >
      <div className="relative flex items-center justify-between">
        <div className="flex items-center">
          <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-full border-4 border-white border-opacity-20">
            <img className="h-full w-full object-cover" src={avatarImgUrl} alt={name} />
          </div>
          <div className="ml-6 max-w-xs font-montserrat text-charizard font-bold text-white">
            {name}
          </div>
        </div>
        <div className="ml-8 flex items-center divide-x divide-opacity-20">
          <div className="px-4">
            <h3 className="text-pikachu font-bold uppercase tracking-widest text-primary-casper">
              Floor
            </h3>
            <div className="mt-[2] text-charizard font-bold text-primary-polar">
              Ξ<span className="ml-1">{floorPrice}</span>
            </div>
          </div>
          <div className="ml-24 px-4">
            <h3 className="text-pikachu font-bold uppercase tracking-widest text-primary-casper">
              Assets
            </h3>
            <div className="mt-[2] text-charizard font-bold text-primary-polar">{assetCount}</div>
          </div>
          <div className="ml-24 px-4">
            <h3 className="text-pikachu font-bold uppercase tracking-widest text-primary-casper">
              Owners
            </h3>
            <div className="mt-[2] text-charizard font-bold text-primary-polar">{ownerCount}</div>
          </div>
          <div className="ml-24 px-4">
            <h3 className="text-pikachu font-bold uppercase tracking-widest text-primary-casper">
              Volume
            </h3>
            <div className="mt-[2] text-charizard font-bold text-primary-polar">
              Ξ<span className="ml-1">{volume}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
