import { PieChart, Pie, Tooltip } from 'recharts';
import cx from 'classnames';

import '../../../styles/main.css';

const COLORS = [
  '#C92AD1',
  '#ED70CE',
  '#F19ED5',
  '#FF00E5',
  '#520B75',
  '#F7CEE6',
  '#9A1EB1',
  '#C92AD1',
];

interface DataItem {
  name: string;
  value: number;
}

interface DataItemWithColor extends DataItem {
  fill: string;
}

export interface PieChartLavaProps {
  className?: string;
  data: DataItem[];
}

const renderColorfulLegendText = (data: DataItemWithColor, totalCount) => {
  return (
    <div className="flex items-center space-x-2">
      <span className="h-3 w-3 rounded-full" style={{ backgroundColor: data.fill }} />
      <span className="text-primary-polar">{data.name}</span>
      <span className="text-primary-casper">{((100 / totalCount) * data.value).toFixed(1)}%</span>
    </div>
  );
};

const getTotal = (data) => {
  return data.reduce((acc, curr) => {
    return acc + curr.value;
  }, 0);
};

const PieChartLava = ({ data, className }: PieChartLavaProps) => {
  const dataWithColors = data.map((item, index) => {
    return {
      ...item,
      fill: COLORS[index % COLORS.length],
    };
  });

  return (
    <div
      className={cx(
        'flex items-center rounded-2xl border border-primary-woodBlue bg-primary-stoneBlue p-10',
        className,
      )}
    >
      <PieChart width={200} height={200} className="flex items-center">
        <Pie
          alignmentBaseline="baseline"
          data={dataWithColors}
          cx="50%"
          cy="50%"
          cornerRadius={4}
          stroke="none"
          innerRadius={40}
          outerRadius={100}
          dataKey="value"
          label={false}
          labelLine={false}
          paddingAngle={4}
        />
        <Tooltip
          contentStyle={{
            backgroundColor: '#0A1826',
            border: 'none',
            borderRadius: '8px',
            color: '#fff',
            background: 'rgba(10, 24, 38, 0.8)',
            padding: '4px 8px',
          }}
          itemStyle={{
            color: '#fff',
          }}
          cursor={false}
        />
      </PieChart>
      <ul className="ml-8 flex flex-wrap">
        {dataWithColors.map((item, index) => (
          <li className="mr-3 mt-3">{renderColorfulLegendText(item, getTotal(data))}</li>
        ))}
      </ul>
    </div>
  );
};

export default PieChartLava;
